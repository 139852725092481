// 设备采购记录
<template>

    <div class="popDevicePurchaseHistory">
        <el-dialog :close-on-click-modal="false"
                   title="设备采购记录"
                   :visible.sync="dialogVisible"
                   width="500"
                   :before-close="close">
            <div class="miniTable">
                <el-table :data="tableData.data"
                          border
                          stripe>
                    <el-table-column type="index"
                                     label="序号"
                                     align="center"
                                     width="100">
                    </el-table-column>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     align="center"
                                     :min-width="col.width">
                    </el-table-column>
                    <!-- 跳转关闭 -->
                    <!-- <el-table-column prop="id"
                                     label="操作"
                                     align="center"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">查看</el-link>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import API from "@/api/projectManage.js";
import { Loading } from "element-ui";

export default {
    name: "popDevicePurchaseHistory",

    props: {
        devicePurchaseItem: {
            type: Object,
            default() {
                return {
                    id: null,
                };
            },
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            tableData: {
                data: [],
                columns: [
                    { id: "code", label: "采购编号", width: "100" },
                    { id: "purchaseDate", label: "采购日期", width: "100" },
                    { id: "purchasePersonName", label: "采购人", width: "100" },
                    { id: "empCount", label: "设备数量", width: "100" },
                    { id: "inStatus.text", label: "入库状态", width: "100" },
                    { id: "inCount", label: "入库数量", width: "100" },
                ],
            },
        };
    },

    created() {},

    mounted() {
        // 获取列表
        this.getList();
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".miniTable"),
            });
            API.purchaseOrderLis({
                taskId: this.devicePurchaseItem.id,
            })
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    // this.pageTotal= res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 跳转采购详情
        details(row) {
            let routeData = this.$router.resolve({
                path: "/projectManage/purchaseDetails",
                query: { selected: row.id, needBackUp: false },
            });
            window.open(routeData.href, "_blank");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>