// 选择设备
<template>

    <div class='pop_chooseDevice'>
        <el-dialog :close-on-click-modal="false"
                   title="选择设备"
                   :visible.sync="dialogVisible"
                   width="600px"
                   :before-close="close">
            <el-form :model="searchForm"
                     ref="searchForm"
                     label-width="80px"
                     :inline="true"
                     size="normal">
                <el-row :gutter="20">
                    <el-col :span="10">
                        <el-form-item label="设备类型">
                            <el-select v-model="searchForm.empTypeId"
                                       placeholder="请选择设备类型"
                                       filterable
                                       clearable>
                                <el-option v-for="item in empTypeIdOpts"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="物料清单">
                            <SelectBomByDeviceType v-model="searchForm.matterId"
                                                   placeholder="请选择物料清单"
                                                   :disabled="searchForm.empTypeId ? false : true"
                                                   :empTypeId="searchForm.empTypeId"></SelectBomByDeviceType>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <div style="padding: 40px 20px 0 0">
                            <el-button type="success"
                                       @click="getList">搜索</el-button>
                        </div>
                    </el-col>
                </el-row>

                <div class="miniTable">
                    <div class="tableBox">
                        <el-table :data="tableData.data"
                                  max-height="300"
                                  ref="deviceTable"
                                  @selection-change="handleSelectionChange"
                                  border
                                  stripe>
                            <el-table-column type="selection"
                                             :selectable='checkBoxDisable'
                                             width="40">
                            </el-table-column>
                            <el-table-column v-for="col in tableData.columns"
                                             :prop="col.id"
                                             :key="col.id"
                                             :label="col.label"
                                             :min-width="col.width">
                            </el-table-column>
                        </el-table>
                        <p class="chooseCount">已选：{{chooseCount}}</p>
                    </div>
                </div>

                <el-form-item class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="onSubmit">确认</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>
    </div>

</template>

<script>
import SelectBomByDeviceType from "../../../components/commonSelect/selectBomByDeviceType";
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
import selectOptsAPI from "@/api/selectOpts.js";
export default {
    name: "pop_chooseDevice",

    props: {
        // 已选择的设备
        isCheckedIds: {
            type: Array,
            default: null,
        },
        // 采购还是部署
        type: {
            type: String,
            default: "采购",
        },
    },

    components: {
        SelectBomByDeviceType,
    },

    data() {
        return {
            dialogVisible: true,
            searchForm: { empTypeId: "" },
            empTypeIdOpts: [{ label: "全部", value: "" }],
            chooseCount: 0, // 已选数量
            tableData: {
                columns: [
                    {
                        id: "empType",
                        label: "设备类型",
                        width: "100",
                    },
                    {
                        id: "provider",
                        label: "供应商",
                        width: "100",
                    },
                    {
                        id: "empTypeModel",
                        label: "规格型号",
                        width: "100",
                    },
                    {
                        id: "unitPrice",
                        label: "价格",
                        width: "100",
                    },
                ],
                data: [],
            },
        };
    },

    created() {},

    mounted() {
        // 获取设备类型下拉数据
        this.getDeviceTypeOpts();
        // 通过设备类型 获取设备列表
        this.getList();
    },

    methods: {
        // el-table选择框是否禁用
        checkBoxDisable(row) {
            if (this.isCheckedIds.indexOf(row.id) !== -1) {
                return false; //禁用
            } else {
                return true; //不禁用
            }
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 获取设备类型下拉数据
        getDeviceTypeOpts() {
            commonAPI.getDeviceTypeOpts().then((res) => {
                let data = res.content;
                let opt = data.map((val) => {
                    return { label: val["label"], value: val["code"] };
                });
                this.empTypeIdOpts = this.empTypeIdOpts.concat(opt);
            });
        },
        // 勾选设备
        handleSelectionChange() {
            this.chooseCount =
                this.$refs.deviceTable.store.states.selection.length;
        },
        // 完成选择设备
        onSubmit() {
            this.$emit("close", this.$refs.deviceTable.store.states.selection);
        },
        // 通过设备类型 获取设备列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".miniTable"),
            });
            if (this.type === "部署") {
                commonAPI
                    .getDeviceOpts({
                        typeMangerId: this.searchForm.empTypeId || "",
                    })
                    .then((res) => {
                        let data = res.content;
                        this.tableData.data = data;
                        loading.close();
                    })
                    .catch(() => {
                        loading.close();
                    });
            } else {
                // 采购
                selectOptsAPI
                    .getDeviceList(this.searchForm)
                    .then((res) => {
                        this.tableData.data = res.content.records;
                        loading.close();
                    })
                    .catch(() => {
                        loading.close();
                    });
            }
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
