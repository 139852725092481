// 实施计划
<template>

    <div class="comImplementationPlan">

        <el-card>
            <div slot="header">实施计划</div>
            <div class="title ofh mb15">
                <template v-if="!disable">
                    <div class="controlBtn flr"
                         v-if="!isDisableUpload">
                        <template v-if="commonJs.isShowBtn(detailRole, 'Btn_新增实施计划')">
                            <el-button type="success"
                                       size="mini"
                                       icon="el-icon-plus"
                                       v-if="control.indexOf('新增') !== -1"
                                       @click="openpopAddImplementationPlan">新增实施计划</el-button>
                        </template>
                    </div>
                </template>
            </div>
            <div class="miniTable">
                <el-table :data="taskList"
                          border
                          stripe>
                    <el-table-column label="序号"
                                     type="index"
                                     align="center"
                                     width="50">
                    </el-table-column>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     align="center"
                                     :min-width="col.width">
                    </el-table-column>
                    <template v-if="!disable">
                        <el-table-column prop="id"
                                         label="操作"
                                         align="center"
                                         min-width="100"
                                         fixed="right">
                            <template slot-scope="scope">
                                <!-- 废止和完成状态的实施计划负责人不可变更 -->
                                <template v-if="control.indexOf('编辑') !== -1">
                                    <el-link type="primary"
                                             v-if="scope.row.taskStage.text !== '归档'"
                                             @click="openpopEditImplementationPlan(scope.row)">编辑</el-link>
                                </template>
                                <!-- 跳转关闭 -->
                                <!-- <template v-if="control.indexOf('查看') !== -1">
                                    <el-link type="primary"
                                             :href="`/#/implementationManage/implementationManageDetails?status=编辑&readOnly=true&id=${scope.row.id}`"
                                             target="_blank">查看</el-link>
                                </template> -->
                                <template v-if="control.indexOf('删除') !== -1">
                                    <el-popconfirm confirm-button-text="删除"
                                                   cancel-button-text="取消"
                                                   icon="el-icon-info"
                                                   v-if="scope.row.deleteAbleFlag"
                                                   icon-color="red"
                                                   @confirm="delImplementationPlan(scope.row)"
                                                   title="真的要删除吗？">
                                        <el-link type="danger"
                                                 slot="reference">删除</el-link>
                                    </el-popconfirm>
                                </template>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
            </div>
        </el-card>

        <!-- 新增实施计划 -->
        <pop-add-implementation-plan v-if="popAddImplementationPlanVisible"
                                     :editItem="editItem"
                                     :resourceId="resourceId"
                                     :control="control"
                                     @close="closepopAddImplementationPlan"></pop-add-implementation-plan>

        <!-- 编辑实施计划 -->
        <pop-edit-implementation-plan v-if="popEditImplementationPlanVisible"
                                      :editImplementationPlan="editImplementationPlan"
                                      @update="update"
                                      @close="closepopEditImplementationPlan"></pop-edit-implementation-plan>

    </div>

</template>

<script>
import PopEditImplementationPlan from "./popEditImplementationPlan";
import popAddImplementationPlan from "./popAddImplementationPlan.vue";
import commonAPI from "@/api/commonAPI.js";
import API from "@/api/safeguardManage.js";
import { Loading } from "element-ui";
export default {
    name: "comImplementationPlan",

    props: {
        taskList: {
            // 实施计划
            type: Array,
            default() {
                return [];
            },
        },
        resourceId: {
            // 项目Id
            type: [String, Number],
            default: "0",
        },
        control: {
            // 控制按钮
            type: Array,
            default() {
                return ["查看"];
            },
        },
        isDisableUpload: {
            // 是否禁止上传或保存 true==禁止 false==允许
            type: Boolean,
            default: true,
        },

        detailRole: {
            type: Object,
        },
        // 全局禁用
        disable: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        PopEditImplementationPlan,
        "pop-add-implementation-plan": popAddImplementationPlan,
    },

    data() {
        return {
            popAddImplementationPlanVisible: false, // 新增实施计划弹窗显示
            popEditImplementationPlanVisible: false, // 编辑实施计划弹窗显示

            editImplementationPlan: null, // 编辑用实施计划详情

            tableData: {
                columns: [
                    { id: "name", label: "名称", width: "100" },
                    { id: "address", label: "实施地点", width: "100" },
                    { id: "operatorName", label: "实施负责人", width: "100" },
                    { id: "startTime", label: "开始时间", width: "100" },
                    { id: "endTime", label: "预计完成时间", width: "100" },
                    { id: "taskStage", label: "当前节点", width: "100" },
                ],
            },
            editItem: {},
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 编辑实施计划 打开弹框
        openpopEditImplementationPlan(row) {
            this.editImplementationPlan = row;
            this.popEditImplementationPlanVisible = true;
        },
        // 编辑实施计划 关闭弹框
        closepopEditImplementationPlan() {
            this.editImplementationPlan = null;
            this.popEditImplementationPlanVisible = false;
        },
        // 新增实施计划 打开弹框
        openpopAddImplementationPlan() {
            this.popAddImplementationPlanVisible = true;
        },
        // 新增实施计划 关闭弹框
        closepopAddImplementationPlan() {
            this.editItem = {};
            this.$emit("update");
            this.popAddImplementationPlanVisible = false;
        },
        // 编辑实施计划
        editTask(item, index) {
            this.editItem = item;
            this.editItem.startTime = this.editItem.startTime
                .replace(/[^\d]/g, "-")
                .slice(0, 10);
            this.editItem.endTime = this.editItem.endTime
                .replace(/[^\d]/g, "-")
                .slice(0, 10);
            this.editItem.index = index;
            this.openpopAddImplementationPlan();
        },
        // 废止项目
        abolishTask(item) {
            let loading = Loading.service({
                target: document.querySelector(".miniTable"),
            });
            let data = {
                id: item.id,
            };
            commonAPI
                .abolishTask(data)
                .then(() => {
                    loading.close();
                    this.$message({ message: "已废止！", type: "success" });
                    this.$emit("update");
                })
                .catch(() => {
                    loading.close();
                });
        },
        update() {
            this.$emit("update");
        },
        // 删除实施计划
        delImplementationPlan(row) {
            let loading = Loading.service({
                target: document.querySelector(".miniTable"),
            });
            API.delTask(row.id)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.$emit("update");
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>
