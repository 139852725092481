// import request from '@/utils/request'
import {
    // get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const notNeedFilterRole = {
    // 采购管理列表
    purchaseOrderLis(parame) {
        return postJson(`/emp/purchase-order/findListNoAuthor`, parame);
    },
};

export default notNeedFilterRole;
