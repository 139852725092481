// 合同
<template>

    <div class="comContract">
        <el-card>
            <div slot="header">
                <span>{{title}}</span>
            </div>
            <div class="contractItemBox"
                 v-if="contractList && contractList.length > 0">
                <div class="title">
                    <span class="bluecircle"></span>
                    <span>{{title}}</span>
                </div>
                <ul class="contractList">
                    <!-- 关闭跳转 -->
                    <!-- @click="toContractDetails(item.id, item.status.name)" -->
                    <li class="contractItem"
                        v-for="(item,idx) in contractList"
                        :key="idx">
                        <div class="titlt ellipsis">
                            <span v-if="item.name && item.name.length<16">{{item.name}}</span>
                            <el-tooltip effect="dark"
                                        v-else
                                        :content="item.name"
                                        placement="top-start">
                                <el-button>上左</el-button>
                                <span>{{item.name}}</span>
                            </el-tooltip>
                        </div>
                        <div class="pic">金额：{{item.projectMoney}}元</div>
                        <div class="status"
                             v-if="item.status">状态：{{item.status.text}}</div>
                        <template v-if="!disable">
                            <el-popconfirm confirm-button-text="删除"
                                           cancel-button-text="取消"
                                           icon="el-icon-info"
                                           icon-color="red"
                                           v-if="!isDisableUpload"
                                           @confirm="del(item)"
                                           title="真的要删除吗？">
                                <div slot="reference"
                                     @click.stop
                                     class="close el-icon-error"></div>
                            </el-popconfirm>
                        </template>
                    </li>
                </ul>

                <div class="totalBar">
                    <i class="blue el-icon-warning mr10"></i>
                    <span v-if="contractList">已添加<i>{{contractList.length}}</i>项</span>
                    <span>金额总计：<i class="blue">{{contractTotalMoney}}</i>元</span>
                </div>
            </div>
            <div class="emptyBg"
                 v-else></div>
        </el-card>
    </div>

</template>

<script>
import API from "@/api/contractManageApi.js";
import { Loading } from "element-ui";
export default {
    name: "comContract",

    props: {
        title: {
            type: String,
            default: "关联合同",
        },
        contractList: {
            // 合同列表
            type: Array,
            default() {
                return [];
            },
        },
        contractTotalMoney: {
            // 合同总金额
            type: [Number, String],
            default: 0,
        },
        isDisableUpload: {
            // 是否禁止上传或保存 true==禁止 false==允许
            type: Boolean,
            default: true,
        },

        // 全局禁用
        disable: {
            type: Boolean,
            default: false,
        },
    },

    components: {},

    data() {
        return {};
    },

    created() {},

    mounted() {},

    methods: {
        // 删除合同
        del(item) {
            let loading = Loading.service({
                target: document.querySelector(".comContract"),
            });
            API.deleteConnectProject(item.projectContractId)
                .then(() => {
                    loading.close();
                    this.$emit("update");
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 跳转到合同详情
        toContractDetails(id, listType) {
            let data = {
                id,
                listType,
                isSeeDetail: true,
                onlySee: true,
            };
            let routeData = this.$router.resolve({
                path: "/contractManage/contractManageDetail",
                query: { data: JSON.stringify(data) },
            });
            window.open(routeData.href, "_blank");
        },
    },

    computed: {
        totalMoney() {
            let data = 0;
            if (this.contractList) {
                this.contractList.forEach((item) => {
                    data += item.money;
                });
            }
            data = data || 0;
            return data;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// 合计栏
.totalBar {
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #9093b3;
    padding-left: 15px;
}
.totalBar span {
    margin-right: 15px;
}
</style>
