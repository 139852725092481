// 删除采购单
// popDelPurchase
<template>

    <div class="popDelPurchase">
        <el-dialog title="删除"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close">
            <p class="title"><i class="el-icon-warning orange"></i>
                <!-- 删除后，已出库的设备将会通过“异常入库”重新进入库存 -->
                删除后，已入库的设备将会通过“异常出库“从库存中消失
            </p>
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-width="80px"
                     :inline="false">
                <el-form-item label="删除描述"
                              v-if="delNeedDescription"
                              prop="description">
                    <el-input v-model="form.description"
                              type="textarea"
                              maxlength="300"
                              rows="7"
                              placeholder="请输入删除描述"
                              show-word-limit="true"
                              clearable></el-input>
                </el-form-item>
                <div class="footer">
                    <el-button type="primary"
                               @click="close">取消</el-button>
                    <!-- <el-button type="danger"
                               @click="save">删除{{delNeedDescription ? "并保存描述" : ""}}</el-button> -->
                    <el-button type="danger"
                               @click="save">确认</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "popDelPurchase",

    props: {
        // 删除用采购ID
        delPurchaseId: {
            type: String,
            default: null,
        },
        // 删除采购单是否需要描述
        delNeedDescription: {
            type: Boolean,
            default: true,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            form: {
                description: "", // 删除描述
                id: this.delPurchaseId,
            },
            formRules: {},
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        save() {
            this.$emit("delPurchase", this.form);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
    margin-bottom: 15px;
}
.popDelPurchase ::v-deep .el-textarea .el-input__count {
    opacity: 0.5;
    bottom: -8px;
    background: none;
}
</style>