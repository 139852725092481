// 新增实施计划
<template>

    <div class="popAddImplementationPlan">
        <el-dialog :close-on-click-modal="false"  title="实施计划"
                   :visible.sync="dialogVisible"
                   custom-class="popAddImplementationPlanDialog"
                   width="600px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-width="120px"
                     :inline="false">
                <el-form-item label="名称"
                              prop="name">
                    <el-input v-model="form.name"
                              placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item prop="customerCompanyId">
                    <span slot="label"><i class="red mr5">*</i>客户</span>
                    <customer-or-provider-select-tree ref="selectTree"
                                                      type="CUSTOMER"
                                                      :checkedInfo="{value: form.customerCompanyId}"
                                                      @treeSelectNodeClick="selectLinkPerson"></customer-or-provider-select-tree>
                </el-form-item>
                <el-form-item label="实施地点"
                              prop="address">
                    <el-input v-model="form.address"
                              disabled
                              placeholder="选择客户后，实施地点会自动变化"></el-input>
                </el-form-item>
                <el-form-item label="实施负责人"
                              prop="operatorName">
                    <el-row>
                        <el-col :span="12">
                            <el-input v-model="form.operatorName"
                                      disabled
                                      suffix-icon="el-icon-arrow-down"
                                      placeholder="请选择实施负责人"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="success"
                                       class="ml15"
                                       size="mini"
                                       @click="openselectPerson">添加</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="开始时间"
                              prop="startTime">
                    <el-date-picker v-model="form.startTime"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="请选择开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="预计完成时间"
                              prop="endTime">
                    <el-date-picker v-model="form.endTime"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="请选择预计完成时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="计划描述"
                              prop="description">
                    <el-input v-model="form.description"
                              type="textarea"
                              rows="4"
                              placeholder="请输入计划描述或计划内容"></el-input>
                </el-form-item>
                <el-form-item align="right"
                              v-if="control.indexOf('编辑') !== -1">
                    <el-button @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>

        <!-- 选择客户 -->
        <pop-select-members v-if="selectPersonVisible"
                            title="添加成员"
                            @close="closeselectPerson"></pop-select-members>

    </div>
</template>

<script>
import popSelectMembers from "@/components/selectTree/popSelectMembers.vue";
import customerOrProviderSelectTree from "@/components/selectTree/customerOrProviderSelectTree.vue";
import { Loading } from "element-ui";
import API from "@/api/safeguardManage.js";
export default {
    name: "popAddImplementationPlan",

    props: {
        editItem: {
            // 编辑实施计划
            type: Object,
            default: null,
        },
        control: {
            // 控制按钮
            type: Array,
            default() {
                return ["查看"];
            },
        },
        resourceId: {
            // 项目Id
            type: [String, Number],
            default: "0",
        },
    },

    components: {
        "customer-or-provider-select-tree": customerOrProviderSelectTree,
        "pop-select-members": popSelectMembers,
    },

    data() {
        return {
            dialogVisible: true,
            selectPersonVisible: false, // 选择人员弹窗显示
            form: {
                name: "", // 名称
                customerCompanyId: "", // 客户
                companyName: "", // 实施地点
                operatorId: "", // 实施负责人Id
                operatorName: "", // 实施负责人姓名
                startTime: "", // 开始时间
                endTime: "", // 预计完成时间
                description: "", // 计划描述
            },
            formRules: {
                // 名称
                name: [{ required: true, message: "请填写名称" }],
                // 实施负责人
                operatorName: [{ required: true, message: "请选择实施负责人" }],
                // 开始时间
                startTime: [{ required: true, message: "请填写开始时间" }],
                // 预计完成时间
                endTime: [{ required: true, message: "请填写预计完成时间" }],
            },
            checkedInfo: null, // 回显客户
        };
    },

    created() {},

    mounted() {
        if (this.editItem) {
            this.form = JSON.parse(JSON.stringify(this.editItem));
            this.checkedInfo = {
                label: this.form.customerCompanyId,
                value: this.form.customerCompanyIdId,
            };
            // 保存时后端接收companyId 返回给我的又是customerCompanyId
            this.form.customerCompanyId = this.editItem.companyId;
        }
    },

    methods: {
        // 联系人的选择
        selectLinkPerson(node) {
            this.$set(this.form, "customerCompanyId", node.id);
            this.$set(this.form, "address", node.address);
        },
        // 选择添加客户
        openselectPerson() {
            this.selectPersonVisible = true;
        },
        // 关闭选择添加客户弹框
        closeselectPerson(id, name) {
            if (id) {
                this.$set(this.form, "operatorId", id); // 项目经理
                this.$set(this.form, "operatorName", name); // 项目经理名字
            }
            this.selectPersonVisible = false;
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 保存
        save() {
            this.$refs.form.validate((valid) => {
                if (valid && this.form.customerCompanyId) {
                    let loading = Loading.service({
                        target: document.querySelector(".popAddImplementationPlanDialog"),
                    });
                    let data = Object.assign({}, this.form, {
                        projectId: this.resourceId,
                        version: this.editItem ? this.editItem.version : 0,
                    });
                    API.saveTask(data)
                        .then(() => {
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            loading.close();
                            this.$emit("close");
                        })
                        .catch(() => {
                            loading.close();
                        });
                } else {
                    this.$message({
                        message: "请检查必填信息是否填写完整！",
                        type: "error",
                    });
                    return false;
                }
            });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>