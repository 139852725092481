// 新增部署
<template>

    <div class='popAddDeployment'>
        <el-dialog :close-on-click-modal="false"
                   :title="`${editDeploymentId?'编辑':'新增'}部署`"
                   :visible.sync="dialogVisible"
                   width="600"
                   :before-close="close">
            <el-form :model="form"
                     ref="popAddDeploymentForm"
                     :rules="rules"
                     label-width="100px"
                     :inline="false"
                     size="normal">
                <el-row>
                    <el-col :span="12">
                        <el-form-item v-if="isNeedTask"
                                      class="fll"
                                      label="实施计划"
                                      prop="taskId">
                            <el-select v-model="form.taskId"
                                       :disabled="editDeploymentId? true : false"
                                       placeholder="请选择">
                                <el-option v-for="item in taskOpts"
                                           :key="item.code"
                                           :disabled="item.disabled"
                                           placeholder="请选择实施计划"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="miniTable clb">
                    <div class="tableBox">
                        <el-table size="mini"
                                  :data="tableData.data"
                                  border
                                  style="width: 100%"
                                  highlight-current-row>
                            <template slot="empty"
                                      v-if="!tableData.data.length">
                                <span class="emptyTable">* 请选择部署设备！</span>
                            </template>
                            <el-table-column type="index"
                                             align="center"
                                             width="50"
                                             label="序号"></el-table-column>
                            <el-table-column prop="empType"
                                             align="center"
                                             label="设备类型"></el-table-column>
                            <el-table-column prop="provider"
                                             align="center"
                                             width="100"
                                             label="供应商"></el-table-column>
                            <el-table-column prop="empTypeModel"
                                             align="center"
                                             width="100"
                                             label="规格型号"></el-table-column>
                            <el-table-column prop="outNumber"
                                             align="center"
                                             width="100"
                                             v-if="editDeploymentId"
                                             label="已出库数量"></el-table-column>
                            <el-table-column prop="number"
                                             align="center"
                                             width="150"
                                             label="数量">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.number"
                                                     :step="1"
                                                     :min="1"
                                                     :controls="false"></el-input-number>
                                    <!-- 又不判断最大了 :max="scope.row.storageNumber" -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="deptStorageNumber"
                                             align="center"
                                             width="100"
                                             label="部门库存"></el-table-column>
                            <el-table-column prop="storageNumber"
                                             align="center"
                                             width="100"
                                             label="总库存"></el-table-column>
                            <el-table-column label="操作"
                                             align="center"
                                             minWidth="50">
                                <template slot-scope="scope">
                                    <template v-if="!scope.row.outNumber">
                                        <div class="controlBox">
                                            <el-popconfirm confirmButtonText='确认'
                                                           cancelButtonText='取消'
                                                           icon="el-icon-info"
                                                           iconColor="red"
                                                           @confirm="delItem(scope.$index)"
                                                           title="确定删除吗？">
                                                <el-link slot="reference"
                                                         type='danger'>删除</el-link>
                                            </el-popconfirm>
                                        </div>
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>

                <div>
                    <p class="addDoneScoreBox"
                       @click="addItem()"><img src="../../../assets/imgs/icon_add.png"
                             alt="">新增设备</p>
                </div>
                <el-form-item class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               class="savePurchaseBtn"
                               :loading="savePurchaseIng"
                               @click="onSubmit">确认</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>
        <!-- 弹框 -->
        <pop-choose-device v-if="chooseDeviceVisible"
                           :isCheckedIds="isCheckedIds"
                           type="部署"
                           @close="closechooseDevice"></pop-choose-device>
    </div>

</template>

<script>
import PopChooseDevice from "../../purchaseManage/components/popChooseDevice";
import { Loading } from "element-ui";
import API from "@/api/purchaseManage.js";
export default {
    name: "popAddDeployment",

    props: {
        detailsId: {
            // 项目Id
            type: String,
            default: "",
        },
        isNeedTask: {
            // 是否需要task选项
            type: Boolean,
            default: false,
        },
        // 编辑用id
        editDeploymentId: {
            type: String,
            default: null,
        },
    },

    components: {
        PopChooseDevice,
    },

    data() {
        return {
            dialogVisible: true,
            chooseDeviceVisible: false, // 选择设备弹窗显示
            form: {
                remark: "", // 备注
                taskId: "", // 实施计划Id
            },
            rules: {
                taskId: [
                    {
                        required: true,
                        message: "请选择实施计划",
                    },
                ],
            },
            savePurchaseIng: false, // 保存中
            isCheckedIds: [], // 列表中已选择的设备id
            tableData: {
                data: [],
            },
            taskOpts: [], // 实施计划下拉
        };
    },

    created() {},

    mounted() {
        // 获取实施计划下拉
        if (this.isNeedTask) {
            this.taskSelect();
        }
        if (this.editDeploymentId) {
            // 获取部署详情
            this.getDeploymentDetails();
        }
    },

    methods: {
        // 获取部署详情
        getDeploymentDetails() {
            let loading = Loading.service({
                target: document.querySelector(".popAddDeployment"),
            });
            API.getDeploymentDetails({
                taskId: this.editDeploymentId,
            })
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.equipmentVoList;
                    this.form.taskId = res.content.taskId;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 提交表单
        onSubmit() {
            this.$refs["popAddDeploymentForm"].validate((valid) => {
                if (valid && this.tableData.data.length > 0) {
                    // 验证成功
                    this.savePurchaseIng = true;
                    let data = Object.assign(
                        {},
                        {
                            equipmentList: this.tableData.data,
                            taskId: this.form.taskId,
                        }
                    );
                    data.equipmentList.forEach((item) => {
                        item.id = null;
                    });
                    API.saveDeployment(data)
                        .then(() => {
                            this.savePurchaseIng = false;
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            this.$emit("update");
                            this.close();
                        })
                        .catch(() => {
                            this.savePurchaseIng = false;
                        });
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },
        // 获取实施计划下拉
        taskSelect() {
            API.taskSelect(this.detailsId)
                .then((res) => {
                    this.taskOpts = res.content;
                })
                .catch(() => {
                    this.$message({
                        message: "查询实施计划下拉失败，请稍后再试！",
                        type: "error",
                    });
                });
        },
        // 添加项目
        addItem() {
            let isChooseArr = [];
            this.tableData.data.forEach((item) => {
                isChooseArr.push(item.empTypeId);
            });
            this.isCheckedIds = isChooseArr;
            this.chooseDeviceVisible = true;
        },
        // 删除项目
        delItem(index) {
            this.tableData.data.splice(index, 1);
        },
        // 关闭采购弹框
        closechooseDevice(item) {
            if (item) {
                let deviceList = item.map((val) => {
                    return {
                        number: 0,
                        empType: val["empType"],
                        empTypeId: val["empTypeId"],
                        provider: val["provider"],
                        empTypeModel: val["empTypeModel"],
                        deptStorageNumber: val["deptStorageNumber"],
                        storageNumber: val["storageNumber"],
                        id: val["id"],
                    };
                });
                this.tableData.data = [...this.tableData.data, ...deviceList];
            }
            this.chooseDeviceVisible = false;
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.addDoneScoreBox {
    padding: 0 17px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    background: #06ccc0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
}
.addDoneScoreBox img {
    width: 14px;
    margin-right: 5px;
    vertical-align: sub;
}
.miniTable .title {
    padding-left: 15px;
    font-size: 14px;
    color: #606266;
    font-weight: 700;
    margin-bottom: 15px;
}
// 预计采购金额
.moneyTip {
    padding-left: 15px;
    font-size: 14px;
    color: #606266;
    font-weight: 700;
    margin-bottom: 35px;
}
</style>
