// 新增采购
<template>

    <div class='addPurchase'>
        <el-dialog :close-on-click-modal="false"
                   :title="`${editPurchaseInfo ? '编辑' : '新增'}采购`"
                   :visible.sync="dialogVisible"
                   width="600"
                   :before-close="close">
            <el-form :model="form"
                     ref="addPurchaseForm"
                     :rules="rules"
                     label-width="100px"
                     :inline="false"
                     size="normal">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="采购日期"
                                      class="fll mr15"
                                      prop="purchaseDate">
                            <el-date-picker v-model=" form.purchaseDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="请选择采购日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item class="fll"
                                      label="采购负责人"
                                      prop="purchasePersonId">
                            <el-select v-model="form.purchasePersonId"
                                       filterable
                                       placeholder="请选择采购负责人">
                                <el-option v-for="item in purchasePersonOpts"
                                           :key="item.code"
                                           placeholder="请选择采购负责人"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item v-if="isNeedTask"
                                      class="fll"
                                      label="采购部门"
                                      prop="belongDeptId">
                            <dept-select-tree placeholderStr="请选择部门"
                                              :needClean="needClean"
                                              :isDisabled="true"
                                              :shouldClean="true"
                                              @handClear="clearDept"
                                              :checkedInfo="{value: form.belongDeptId, label: form.belongDeptName}"
                                              @treeSelectNodeClick="departmentClick"></dept-select-tree>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- 支出金额 -->
                <p class="moneyTip">支出单据金额：{{totalMoney}}</p>

                <div class="miniTable clb mb20">
                    <div class="title">
                        <span>关联采购单：（支出单据）</span>
                        <p class="addDoneScoreBox flr"
                           @click="openpopSelectTicket"><img src="../../../assets/imgs/icon_add.png"
                                 alt="">添加支出单据</p>
                    </div>
                    <div class="tableBox">
                        <el-table size="mini"
                                  :data="form.receiptsList"
                                  border
                                  style="width: 100%"
                                  highlight-current-row>
                            <template slot="empty"
                                      v-if="!form.receiptsList.length">
                                <span class="emptyTable">* 关联采购单！</span>
                            </template>
                            <el-table-column align="center"
                                             width="70"
                                             label="单据">
                                <template slot-scope="scope">
                                    <span>单据{{scope.$index + 1}}</span>
                                </template></el-table-column>
                            <el-table-column prop="date"
                                             width="90"
                                             label="日期"></el-table-column>
                            <el-table-column prop="incomeCode"
                                             width="140"
                                             label="编号"></el-table-column>
                            <el-table-column prop="projectName"
                                             width="100"
                                             label="项目"></el-table-column>
                            <el-table-column prop="chargeUserName"
                                             width="100"
                                             label="业务员"></el-table-column>
                            <el-table-column prop="deptName"
                                             width="100"
                                             label="部门"></el-table-column>
                            <el-table-column prop="actualAmount"
                                             width="100"
                                             label="金额"></el-table-column>
                            <el-table-column prop="remark"
                                             width="100"
                                             label="备注"></el-table-column>
                            <el-table-column label="操作"
                                             align="center"
                                             fixed="right"
                                             width="80">
                                <template slot-scope="scope">
                                    <div class="controlBox">
                                        <el-popconfirm confirmButtonText='确认'
                                                       cancelButtonText='取消'
                                                       icon="el-icon-info"
                                                       iconColor="red"
                                                       @confirm="delItem(scope.$index)"
                                                       title="确定删除吗？">
                                            <el-link slot="reference"
                                                     v-if="!(scope.row.inCount > 0)"
                                                     type='danger'>删除</el-link>
                                        </el-popconfirm>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>

                <div class="cgdWarp"
                     v-if="form.receiptsList.length">
                    <div class="title moneyTip"
                         style="margin-bottom: 10px">
                        采购设备
                    </div>
                    <div class="miniTable clb mb10"
                         v-for="(item,idx) in form.receiptsList"
                         :key="idx">
                        <div class="title">
                            <span>采购单{{idx+1}}</span>
                            <p class="addDoneScoreBox flr"
                               @click="addItem(idx)"><img src="../../../assets/imgs/icon_add.png"
                                     alt="">添加设备</p>
                            <div class="totalBox">
                                <span>单据金额：{{item.actualAmount || 0}}</span>
                                <span>设备金额：{{item.deviceAmount || 0}}</span>
                            </div>
                        </div>
                        <div class="tableBox">
                            <el-table size="mini"
                                      :data="item.purchaseViewList"
                                      border
                                      style="width: 100%"
                                      highlight-current-row>
                                <template slot="empty"
                                          v-if="!item.purchaseViewList.length">
                                    <span class="emptyTable">* 请选择采购的设备！</span>
                                </template>
                                <el-table-column type="index"
                                                 align="center"
                                                 width="50"
                                                 label="序号"></el-table-column>
                                <el-table-column prop="empType"
                                                 width="auto"
                                                 label="设备类型"></el-table-column>
                                <el-table-column prop="provider"
                                                 width="auto"
                                                 label="供应商"></el-table-column>
                                <el-table-column prop="typeModel"
                                                 width="100"
                                                 label="规格型号"></el-table-column>
                                <el-table-column prop="inCount"
                                                 width="auto"
                                                 align="center"
                                                 v-if="editPurchaseInfo"
                                                 label="入库数量"></el-table-column>
                                <el-table-column prop="unitPrice"
                                                 label="价格"
                                                 width="150">
                                    <template slot-scope="scope">
                                        <el-input-number v-model="scope.row.unitPrice"
                                                         :step="1"
                                                         :min="0"
                                                         :controls="false"
                                                         @change="changeMoney(idx)"
                                                         :disabled="scope.row.inCount > 0"></el-input-number>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="purchaseCount"
                                                 label="数量"
                                                 width="150">
                                    <template slot-scope="scope">
                                        <el-input-number v-model="scope.row.purchaseCount"
                                                         :step="1"
                                                         :min="1"
                                                         @change="changeMoney(idx)"
                                                         :controls="false"></el-input-number>
                                    </template>
                                </el-table-column>
                                <el-table-column label="总价"
                                                 width="90">
                                    <template slot-scope="scope">
                                        {{((scope.row.unitPrice || 0) * (scope.row.purchaseCount || 0)).toFixed(2)}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作"
                                                 align="center"
                                                 width="90">
                                    <template slot-scope="scope">
                                        <div class="controlBox">
                                            <el-popconfirm confirmButtonText='确认'
                                                           cancelButtonText='取消'
                                                           icon="el-icon-info"
                                                           iconColor="red"
                                                           @confirm="delPurchaseItem(idx, scope.$index)"
                                                           title="确定删除吗？">
                                                <el-link slot="reference"
                                                         v-if="!(scope.row.inCount > 0)"
                                                         type='danger'>删除</el-link>
                                            </el-popconfirm>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>

                <el-form-item label="备注"
                              prop="remark">
                    <el-input type="textarea"
                              :rows="4"
                              placeholder="请输入备注"
                              v-model="form.remark">
                    </el-input>
                </el-form-item>
                <el-form-item label="采购依据">
                    <com-file-upload-list :showUpBtn="true"
                                          v-if="form.id"
                                          :neddLoadFile="true"
                                          :uploadData="{resourceId: form.id,
                                          resourceType:'purchaseOrder'}"></com-file-upload-list>
                </el-form-item>
                <el-form-item class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               class="savePurchaseBtn"
                               :loading="savePurchaseIng"
                               @click="onSubmit">确认</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>
        <!-- 弹框 -->
        <pop-choose-device v-if="chooseDeviceVisible"
                           :isCheckedIds="isCheckedIds"
                           @close="closechooseDevice"></pop-choose-device>
        <!-- 选择支出单据 -->
        <PopSelectTicket v-if="popSelectTicketVisible"
                         incomeOrPay="pay"
                         dialogTitle="支出单据"
                         :companyId="form.companyId"
                         @checkTicket="handleCheckTicket"
                         @close="closepopSelectTicket"></PopSelectTicket>
    </div>

</template>

<script>
import PopSelectTicket from "../../billManage/components/popSelectTicket";
import DeptSelectTree from "../../../components/selectTree/deptSelectTree";
import PopChooseDevice from "./popChooseDevice";
import ComFileUploadList from "../../../components/upload/comFileUploadList";
import { Loading } from "element-ui";
import API from "@/api/purchaseManage.js";
import commonAPI from "@/api/commonAPI.js";
import { mapGetters } from "vuex";
export default {
    name: "addPurchase",

    props: {
        detailsId: {
            // 项目Id
            type: String,
            default: "",
        },
        isNeedTask: {
            // 是否需要task选项
            type: Boolean,
            default: false,
        },
        // 项目详情
        projectDetails: {
            type: Object,
            default: null,
        },
        // 编辑采购的id
        editPurchaseInfo: {
            type: String,
            default: null,
        },
    },

    components: {
        PopSelectTicket,
        DeptSelectTree,
        PopChooseDevice,
        ComFileUploadList,
    },

    data() {
        return {
            dialogVisible: true,
            chooseDeviceVisible: false, // 选择设备弹窗显示
            form: {
                purchaseDate: "", // 采购日期
                remark: "", // 备注
                purchasePersonId: "", // 采购负责人
                receiptsList: [], // 单据
            },
            rules: {
                purchaseDate: [
                    {
                        required: true,
                        message: "请选择采购日期",
                    },
                ],
                taskId: [
                    {
                        required: true,
                        message: "请选择实施计划",
                    },
                ],
                purchasePersonId: [
                    {
                        required: true,
                        message: "请选择采购负责人",
                    },
                ],
            },
            savePurchaseIng: false, // 保存中
            isCheckedIds: [], // 列表中已选择的设备id
            tableData: {
                data: [],
            },
            projectOpts: [], // 关联项目
            purchasePersonOpts: [], // 采购负责人下拉
            popSelectTicketVisible: false, // 选择单据弹窗显示
            editIndex: null, // 正在添加的采购单序号
        };
    },

    created() {},

    mounted() {
        // 采购日期默认为当前日期
        this.form.purchaseDate = this.commonJs.nowTime();
        // 公司下的联系人列表
        this.getPersonList();
        // 项目名称 下拉列表
        this.getItemSelect();
        // 新增
        if (!this.editPurchaseInfo) {
            this.form.purchasePersonId = this.userInfo.id;
            // 采购部门为当前登录人部门
            this.form.belongDeptId = this.belongDeptId;
            //如果是新增 生成id
            this.getUniqueId();
        } else {
            // 获取详情
            this.getPurchseDetails();
        }
    },

    methods: {
        //如果是新增 生成id
        getUniqueId() {
            this.iscreating = true;
            commonAPI
                .getUniqueId()
                .then((res) => {
                    this.iscreating = false;
                    this.$set(this.form, "id", res.content);
                })
                .catch(() => {
                    this.iscreating = false;
                });
        },
        // 获取设备采购详情
        getPurchseDetails() {
            let loading = Loading.service({
                target: document.querySelector(".body"),
            });
            API.getPurchseDetails(this.editPurchaseInfo.id)
                .then((res) => {
                    loading.close();
                    this.form = res.content;
                    this.form.inStatus &&
                        (this.form.inStatus = this.form.inStatus.name);
                    this.form.receiptsList.forEach((item, idx) => {
                        // 采购单修改金额和数量
                        this.changeMoney(idx);
                        item.purchaseViewList.forEach((it) => {
                            delete it.haveCode;
                        });
                    });
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 提交表单
        onSubmit() {
            this.$refs["addPurchaseForm"].validate((valid) => {
                if (valid && this.form.receiptsList.length > 0) {
                    this.form.receiptsList.forEach((item) => {
                        delete item.date;
                    });
                    // 验证成功
                    this.savePurchaseIng = true;
                    let data = Object.assign({}, this.form, {});
                    if (this.projectDetails) {
                        data.projectId = this.projectDetails.id;
                    }
                    API.savePurchase(data)
                        .then(() => {
                            this.savePurchaseIng = false;
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            this.$emit("update");
                            this.close();
                        })
                        .catch(() => {
                            this.savePurchaseIng = false;
                        });
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },
        // 公司下的联系人列表
        getPersonList() {
            let loading = Loading.service({
                target: document.querySelector(".personList"),
            });
            return commonAPI
                .getPersonList({
                    status: "NORMAL",
                })
                .then((res) => {
                    this.purchasePersonOpts = res.content;
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 项目名称 下拉列表
        getItemSelect() {
            commonAPI
                .getItemSelectWithStatus({ status: [1, 2] })
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            label: val["label"],
                            code: val["code"],
                        };
                    });
                    this.projectOpts = opt;
                })
                .catch(() => {
                    this.$message({
                        message: "获取项目名称失败，请稍后再试！",
                        type: "error",
                    });
                });
        },
        // 添加项目
        addItem(idx = null) {
            this.editIndex = idx;
            let isChooseArr = [];
            this.form.receiptsList.forEach((it) => {
                isChooseArr.push(it.modelId);
            });
            this.isCheckedIds = isChooseArr;
            this.chooseDeviceVisible = true;
        },
        // 删除采购设备
        delPurchaseItem(index, idx) {
            this.form.receiptsList[index].purchaseViewList.splice(idx, 1);
        },
        // 删除项目
        delItem(index) {
            this.form.receiptsList.splice(index, 1);
        },
        // 关闭采购弹框
        closechooseDevice(item) {
            if (item) {
                let deviceList = item.map((val) => {
                    return {
                        modelId: val["id"],
                        purchaseCount: 0,
                        unitPrice: val["unitPrice"] || 0,
                        empType: val["empType"],
                        provider: val["provider"],
                        typeModel: val["empTypeModel"],
                    };
                });
                this.$set(
                    this.form.receiptsList[this.editIndex],
                    "purchaseViewList",
                    [
                        ...this.form.receiptsList[this.editIndex]
                            .purchaseViewList,
                        ...deviceList,
                    ]
                );
                // 采购单修改金额和数量
                this.changeMoney(this.editIndex);
            }
            this.editIndex = null;
            this.chooseDeviceVisible = false;
        },
        //部门的选择
        departmentClick(node) {
            this.form.belongDeptId = node.id;
        },
        // 选择单据 打开弹框
        openpopSelectTicket() {
            this.popSelectTicketVisible = true;
        },
        // 选择单据 关闭弹框
        closepopSelectTicket() {
            this.popSelectTicketVisible = false;
        },
        // 选择单据 子组件返回数据
        handleCheckTicket(rows) {
            let data = rows.map((item) => {
                return {
                    actualAmount: item.executedAmount || item.amount, // 实际收付款金额
                    chargeUserName: item.chargeUserName, // 业务员
                    contractId: item.incomeId,
                    code: item.incomeCode, // 编号
                    date: item.date, // 日期
                    deptName: item.deptName, // 部门
                    executedAmount: 0,
                    expendType: item.type, // 合同还是其他
                    incomeCode: item.incomeCode, // 编号
                    projectName: item.projectName, // 项目
                    purchaseViewList: [],
                    remark: item.remark, // 备注
                    deviceAmount: 0, // 设备金额
                    // chargeUserId: "",
                    // companyName: "",
                    // deptId: "",
                    // name: "",
                    // orderId: "",
                };
            });
            this.form.receiptsList = this.form.receiptsList.concat(data);
        },
        // 采购单修改金额和数量
        changeMoney(index) {
            let money = 0;
            this.form.receiptsList[index].purchaseViewList.forEach((item) => {
                money =
                    money + (item.unitPrice || 0) * (item.purchaseCount || 1);
            });
            money = money.toFixed(2);
            this.$set(this.form.receiptsList[index], "deviceAmount", money);
            console.log(1111, this.form.receiptsList[index].deviceAmount);
        },
    },

    computed: {
        ...mapGetters(["userInfo"]),
        // 采购部门
        belongDeptId() {
            if (this.projectDetails) {
                return this.projectDetails.belongDeptId || null;
            } else {
                return this.userInfo.deptId || null;
            }
        },
        // 支出单据金额
        totalMoney() {
            let money = 0;
            if (this.form) {
                this.form.receiptsList.forEach((item) => {
                    money += item.actualAmount * 1;
                });
                money = money.toFixed(2);
            }
            return money;
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
.addDoneScoreBox {
    padding: 0 10px;
    display: inline-block;
    height: 28px;
    line-height: 28px;
    background: #06ccc0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    margin: 0;
}
.addDoneScoreBox img {
    width: 12px;
    margin-right: 5px;
}
.miniTable .title {
    font-size: 14px;
    line-height: 28px;
    color: #606266;
    font-weight: 700;
    margin-bottom: 15px;
    overflow: hidden;
    .totalBox {
        float: right;
        margin-right: 50px;
        span {
            margin-left: 30px;
        }
    }
}
// 预计采购金额
.moneyTip {
    padding-left: 15px;
    font-size: 14px;
    color: #606266;
    font-weight: 700;
    margin-bottom: 35px;
}
.el-input-number {
    width: 100%;
}
</style>
