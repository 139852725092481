// 采购设备总表
<template>

    <div class="popDevicePurchaseHistory">
        <el-dialog :close-on-click-modal="false"  title="采购设备总表"
                   :visible.sync="dialogVisible"
                   width="500"
                   :before-close="close">
            <div class="miniTable mb15">
                <el-table :data="tableData.data"
                          border
                          stripe>
                    <el-table-column type="index"
                                     label="序号"
                                     align="center"
                                     width="100">
                    </el-table-column>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     align="center"
                                     :min-width="col.width">
                    </el-table-column>
                </el-table>
            </div>
            <div class="ofh">
                <el-button class="flr"
                           type="primary"
                           size="mini"
                           @click="exportExcel">导出</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import API from "@/api/projectManage.js";
import { Loading } from "element-ui";

export default {
    name: "popDevicePurchaseHistory",

    props: {
        detailsId: {
            // 项目Id
            type: [String, Number],
            default: "",
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            tableData: {
                data: [],
                columns: [
                    { id: "empType", label: "设备类型", width: "100" },
                    { id: "empTypeModel", label: "规格型号", width: "100" },
                    { id: "provider", label: "供应商", width: "100" },
                    { id: "number", label: "数量", width: "100" },
                ],
            },
        };
    },

    created() {},

    mounted() {
        // 获取列表
        this.getList();
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".miniTable"),
            });
            API.getAllDeviceBuyList({
                projectId: this.detailsId,
            })
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content;
                    // this.pageTotal= res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 导出
        exportExcel() {
            window.location.href = `${window.configPath.baseUrl}/emp/purchase-order/exportModelListByProjectId?projectId=${this.detailsId}`;
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>