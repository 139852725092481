// 项目人员权限管理
<template>

    <div class="popPersonItemPowerManage">
        <el-drawer title=""
                   :visible.sync="dialogVisible"
                   direction="rtl"
                   :before-close="close">
            <div class="warp personListBox">
                <div class="searchForm mb20">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :inline="true">
                        <el-form-item label="姓名"
                                      prop="userName">
                            <el-input v-model="searchForm.userName"
                                      placeholder="请输入姓名"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary"
                                       @click="getList">筛选</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="addPersonBtn"
                     v-if="commonJs.isShowBtn(detailRole, 'Btn_成员管理_添加成员')"
                     @click="openselectPerson">+ 添加成员</div>

                <ul class="personList">
                    <li class="personItem"
                        v-for="(item,idx) in personList"
                        :key="idx">
                        <el-row :gutter="10">
                            <el-col :span="6">
                                <span class="bluecircle"></span>
                                <span class="name">{{item.userName}}</span>
                            </el-col>
                            <el-col :span="8">
                                <span class="jobName">
                                    <template>{{item.position}}</template>
                                </span>
                            </el-col>
                            <el-col :span="10"
                                    v-if="item.position !== '项目经理'">
                                <el-select v-model="item.roleId"
                                           placeholder="请选择">
                                    <el-option v-for="item in roleOpts"
                                               :key="item.code"
                                               :label="item.label"
                                               :value="item.code">
                                    </el-option>
                                </el-select>
                                <el-popconfirm confirm-button-text="删除"
                                               cancel-button-text="取消"
                                               icon="el-icon-info"
                                               icon-color="red"
                                               @confirm="del(idx)"
                                               title="真的要删除吗？">
                                    <el-link type="danger"
                                             v-if="item.position === '成员'"
                                             slot="reference">移除</el-link>
                                </el-popconfirm>
                            </el-col>
                        </el-row>

                    </li>
                </ul>
                <footer style="text-align: right">
                    <el-button type="success"
                               size="default"
                               v-role="commonJs.getBtnRoles(detailRole,'Btn_成员管理_保存')"
                               @click="save">保存</el-button>
                </footer>

            </div>
        </el-drawer>

        <!-- 添加成员 -->
        <pop-select-members v-if="selectPersonVisible"
                            title="添加成员"
                            :showCheckbox="true"
                            :filterPersonIdArr="filterPersonIdArr"
                            @close="closeselectPerson"></pop-select-members>
    </div>
</template>

<script>
import popSelectMembers from "@/components/selectTree/popSelectMembers.vue";
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popPersonItemPowerManage",

    props: {
        resourceId: {
            // 项目Id
            type: [String, Number],
            default: "0",
        },
        detailRole: {
            type: Object,
        },
    },

    components: {
        "pop-select-members": popSelectMembers,
    },

    data() {
        return {
            dialogVisible: true,
            activeNames: [0],
            searchForm: { userName: "" },
            selectPersonVisible: false, // 添加成员弹窗显示
            personList: [], // 人员列表
            test: "",
            roleOpts: [], // 角色选项
            filterPersonIdArr: [], // 需要排除的人员id
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            // 获取项目下的人员列表
            this.getList();
            // 获取角色下拉
            this.getRoleList();
        },
        // 获取项目下的人员列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".personListBox"),
            });
            let data = Object.assign({}, this.searchForm, {
                order: "DESC",
                projectId: this.resourceId,
            });
            commonAPI
                .itemPersonList(data)
                .then((res) => {
                    loading.close();
                    this.personList = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取角色下拉
        getRoleList() {
            let loading = Loading.service({
                target: document.querySelector(".personListBox .el-select"),
            });
            commonAPI
                .getRoleList({
                    roleType: "PROJECT_ROLE",
                })
                .then((res) => {
                    loading.close();
                    this.roleOpts = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 移除成员
        del(idx) {
            this.personList.splice(idx, 1);
        },
        // 保存成员
        save() {
            let flag = this.personList.every((item) => {
                return item.roleId;
            });
            if (flag) {
                let loading = Loading.service({
                    target: document.querySelector(".personListBox"),
                });
                // let data = this.personList;
                commonAPI
                    .savePersonList(this.personList)
                    .then(() => {
                        loading.close();
                        this.$message({
                            message: "保存成功！",
                            type: "success",
                        });
                        // 更新数据
                        this.update();
                    })
                    .catch(() => {
                        loading.close();
                        // 更新数据
                        this.update();
                    });
            } else {
                this.$message({ message: "请选择角色", type: "error" });
            }
        },

        // 选择添加客户
        openselectPerson() {
            let idsArr = [];
            this.personList.forEach((item) => {
                idsArr.push(item.userId);
            });
            this.filterPersonIdArr = idsArr;
            this.selectPersonVisible = true;
        },
        // 关闭选择添加客户弹框
        closeselectPerson(id, name) {
            if (id) {
                if (typeof id !== "object") {
                    this.personList.push({
                        userName: name,
                        userId: id,
                        roleId: "",
                        projectId: this.resourceId,
                        position: "成员",
                    });
                } else {
                    // 多选
                    let data = id.choosePersonInfo.map((item) => {
                        return {
                            userName: item.userName,
                            userId: item.id,
                            roleId: "",
                            projectId: this.resourceId,
                            position: "成员",
                        };
                    });
                    this.personList = [...this.personList, ...data];
                }
            }
            this.filterPersonIdArr = [];
            this.selectPersonVisible = false;
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 手风琴折起
        handleChange(val) {
            console.log(val);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.popPersonItemPowerManage ::v-deep .el-collapse-item__content,
.popPersonItemPowerManage ::v-deep .el-collapse-item__header {
    padding: 0 20px;
}
.warp {
    padding: 0 25px 25px;
}
.addPersonBtn {
    width: 100%;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    background-color: rgba(243, 246, 249, 100);
    color: rgba(16, 16, 16, 0.8);
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border: 1px solid rgba(217, 217, 217, 100);
}
.personList {
    padding: 0;
    margin: 20px 0;
    max-height: calc(100vh - 270px);
    overflow-x: hidden;
    overflow-y: auto;
}
.personList .personItem {
    line-height: 35px;
    padding: 15px 0;
    font-size: 14px;
    color: #101010;
}
.personList .personItem .delPerson {
    width: 30px;
    margin-left: 20px;
    cursor: pointer;
    color: #ff0000;
}
.personList .personItem .el-select {
    width: calc(100% - 50px);
}
</style>