// 根据设备类型查询物料清单
<template>

    <div class="selectBomByDeviceType">
        <el-select v-model="bomId"
                   :filterable="filterable"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   :clearable="clearable"
                   @change="change">
            <el-option v-for="item in options"
                       :value-key="item.value"
                       :key="returnType === 'String' ? item.value : item"
                       :label="item.label"
                       :value="returnType === 'String' ? item.value : item">
            </el-option>
        </el-select>
    </div>

</template>
<script>
import API from "@/api/safeguardManage.js";
export default {
    name: "selectBomByDeviceType",
    components: {},

    props: {
        // v-model绑定的值
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "请选择",
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // 设备类型id
        empTypeId: {
            default: null,
        },
        // 返回值类型 ["String", "Object"]
        returnType: {
            type: String,
            default: "String",
        },
    },

    data() {
        return {
            bomId: "",
            options: [], // 选项
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 获取设备类型下拉数据
        getOpts() {
            if (this.empTypeId) {
                API.getBomOpts(this.empTypeId).then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return { label: val["name"], value: val["id"] };
                    });
                    this.options = opt;
                });
            } else {
                API.getBomByEmpTypeModelIdOpts(null).then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return { label: val["name"], value: val["id"] };
                    });
                    this.options = opt;
                });
            }
        },
        // 触发change事件
        change() {
            this.$emit("change");
        },
    },

    computed: {},

    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(newValue) {
                this.bomId = newValue;
            },
        },
        bomId() {
            this.$emit("input", this.bomId);
        },
        empTypeId: {
            deep: true,
            immediate: true,
            handler() {
                // 获取设备类型下拉数据
                this.getOpts();
            },
        },
    },
};
</script>