// 被关联增补项目
<template>

    <div class="comLinkItem">
        <el-card>
            <div slot="header">
                <span>增补项目</span>
            </div>
            <div class="linkItemBox"
                 v-if="true">
                <!-- v-if="linkItemList && linkItemList.length > 0" -->
                <div class="title mb15">
                    <span class="bluecircle"></span>
                    <span>项目</span>
                </div>
                <template v-if="!disable">
                    <el-link type="primary"
                             v-if="projectDetails.supplemenFlag === '是'"
                             @click="openpopSelectReserveProject">点击添加项目</el-link>
                </template>
                <ul class="linkItemList ofh"
                    v-if="linkItemList.length>0">
                    <li v-for="(item,idx) in linkItemList"
                        :key="idx"
                        class="fll">
                        <!-- 关闭跳转 -->
                        <!-- @click="toProjectDetails(item.id)" -->
                        <div class="linkItem">
                            <div class="titlt">{{item.name}}</div>
                            <div class="pic">项目产品：{{item.projectSystem}}</div>
                            <div class="pic">合同状态：
                                {{item.contractStatus}}
                            </div>
                            <template v-if="!disable">
                                <el-popconfirm confirm-button-text="删除"
                                               cancel-button-text="取消"
                                               icon="el-icon-info"
                                               v-if="!isDisableUpload"
                                               icon-color="red"
                                               @confirm="del(item)"
                                               title="真的要删除吗？">
                                    <div slot="reference"
                                         @click.stop
                                         class="close el-icon-error"></div>
                                </el-popconfirm>
                            </template>
                        </div>
                    </li>
                </ul>
                <div class="emptyBg"
                     v-else></div>

                <div class="totalBar">
                    <i class="blue el-icon-warning mr10"></i>
                    <span v-if="linkItemList">已添加<i>{{linkItemList.length}}</i>项</span>
                    <span>增补金额总计：<i class="blue">{{supplementQuiltTotalMoney}}</i>元</span>
                </div>
            </div>
            <div class="emptyBg"
                 v-else></div>
        </el-card>

        <!-- 选择项目 -->
        <pop-select-project v-if="popSelectProjectVisible"
                            :resourceId="resourceId"
                            type="item"
                            @close="closepopSelectProject"></pop-select-project>
    </div>

</template>

<script>
import popSelectProject from "./popSelectProject";
import { Loading } from "element-ui";
import API from "@/api/projectManage.js";

export default {
    name: "comLinkItemed",

    props: {
        title: {
            type: String,
            default: "关联合同",
        },
        resourceId: {
            // 项目Id
            type: [String, Number],
            default: "0",
        },
        projectDetails: {
            type: Object,
            default() {
                return {
                    reserveProjectName: "", // 关联储备项目
                    companyId: "", // 客户
                    projectSystem: "", // 项目产品
                    managerId: "", // 项目经理
                    startTime: "", // 项目开始时间
                    planFinishTime: "", // 计划交付日期
                    money: "", // 项目规模（元）
                    supplemenFlag: "", // 是否增补项目
                    content: "", // 项目内容
                };
            },
        },
        supplementQuiltTotalMoney: {
            // 分配金额总计
            type: [Number, String],
            default: 0,
        },
        linkItemList: {
            // 增补项目列表
            type: Array,
            default() {
                return [];
            },
        },
        // 全局禁用
        disable: {
            type: Boolean,
            default: false,
        },
        isDisableUpload: {
            // 是否禁止上传或保存 true==禁止 false==允许
            type: Boolean,
            default: true,
        },
    },

    components: {
        "pop-select-project": popSelectProject,
    },

    data() {
        return {
            popSelectProjectVisible: false, // 选择项目弹窗显示
            overMoney: false, // 是否超出
            allCount: 0, // 分配金额总计
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 选择项目 打开弹框
        openpopSelectReserveProject() {
            this.popSelectProjectVisible = true;
        },
        // 选择项目 关闭弹框
        closepopSelectProject() {
            this.$emit("update");
            this.popSelectProjectVisible = false;
        },
        // 删除增补项目
        del(item) {
            let loading = Loading.service({
                target: document.querySelector(".comLinkItem"),
            });
            API.dellinkItemFriendship({
                id: item.supplementId,
            })
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.$emit("update");
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 跳转到项目详情
        toProjectDetails(id) {
            let routeData = this.$router.resolve({
                path: "/projectManage/projectManageDetails",
                query: {
                    status: "编辑",
                    id,
                },
            });
            window.open(routeData.href, "_blank");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// 合同项
.linkItemBox {
    width: 100%;
}
.linkItemBox {
    font-size: 14px;
}
.linkItemBox > .title span {
    vertical-align: middle;
}
.linkItemList {
    background-color: #f3f3f3;
    padding: 20px;
}
.linkItemList li {
    overflow: hidden;
    padding-top: 10px;
}
.linkItemList .linkItem {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
    line-height: 20px;
    font-size: 14px;
    padding: 8px 15px;
    padding-left: 92px;
    width: 380px;
    height: 101px;
    background: #fbfbfb url("../../../assets/imgs/icon_contract.png") no-repeat
        28px center;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #595959;
    line-height: 26px;
}
.linkItemList .linkItem .close {
    font-size: 18px;
    position: absolute;
    right: -8px;
    top: -8px;
    color: #1990ff;
    cursor: pointer;
    transition: all 0.5s;
}
.linkItemList .linkItem .close:hover {
    color: #ff0000;
    transition: all 0.5s;
}
.linkItemList .moneyControl {
    float: left;
    margin-left: 50px;
}
.linkItemList .moneyControl .label {
    margin-bottom: 15px;
}
.linkItemList .moneyControl .red {
    font-size: 12px;
}
// 合计栏
.totalBar {
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #9093b3;
    padding-left: 15px;
}
.totalBar span {
    margin-right: 15px;
}
</style>
