// 选择储备项目
<template>

    <div class="popSelectReserveProject">
        <el-dialog :close-on-click-modal="false"
                   title="选择储备项目"
                   :visible.sync="dialogVisible"
                   width="1000px"
                   :before-close="close">
            <div class="searchForm mb15 ">
                <el-form :model="searchForm"
                         ref="searchForm"
                         :inline="true">
                    <el-form-item label="项目名称"
                                  prop="name">
                        <el-input v-model="searchForm.name"
                                  placeholder="请输入项目名称"></el-input>
                    </el-form-item>
                    <el-form-item label="项目客户"
                                  prop="customerCompanyId">
                        <customer-or-provider-select-tree ref="selectTree"
                                                          type="CUSTOMER"
                                                          @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                    </el-form-item>
                    <el-form-item label="软件信息"
                                  prop="systemsInvolvedId">
                        <select-project ref="selectProject"
                                        @select="selectSystem"></select-project>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary"
                                   @click="getList">筛选</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="miniTable mb30">
                <el-table ref="multipleTable"
                          :data="tableData"
                          height="500px"
                          tooltip-effect="dark"
                          style="width: 100%">
                    <el-table-column label="选择"
                                     align="center"
                                     min-width="65">
                        <template slot-scope="scope">
                            <el-radio :label="scope.$index"
                                      v-model="radio"
                                      @change.native="getCurrentRow(scope.row)"></el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name"
                                     label="项目名称"
                                     align="center"
                                     min-width="120"></el-table-column>
                    <el-table-column prop="content"
                                     label="项目内容"
                                     align="center"
                                     min-width="120">
                        <template slot-scope="scope">
                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="scope.row.content"
                                        placement="top">
                                <p class="ellipsis2">{{scope.row.content}}</p>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="customerCompanyName"
                                     label="项目客户"
                                     align="center"
                                     min-width="120"></el-table-column>
                    <el-table-column prop="sysNames"
                                     label="软件信息"
                                     align="center"
                                     min-width="120"></el-table-column>
                </el-table>
            </div>

            <div class="footerControl">
                <el-button type="default"
                           size="medium"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           size="medium"
                           @click="save">保存</el-button>

            </div>

        </el-dialog>

    </div>
</template>

<script>
import selectProject from "@/components/commonSelect/selectProject.vue";
import customerOrProviderSelectTree from "@/components/selectTree/customerOrProviderSelectTree.vue";
import { Loading } from "element-ui";
import API from "@/api/projectManage.js";
export default {
    name: "popSelectReserveProject",

    props: [],

    components: {
        "customer-or-provider-select-tree": customerOrProviderSelectTree,
        "select-project": selectProject,
    },

    data() {
        return {
            dialogVisible: true,
            searchForm: {
                name: "", // 项目名称
                customerCompanyId: "", // 项目客户
                systemsInvolvedId: "", // 软件信息
                status: "", // 项目状态，该处查非归档的
                order: "DESC",
            },
            radio: "",
            tableData: [],
            templateSelection: [],
        };
    },

    created() {},

    mounted() {
        // 获取储备项目列表
        this.getList();
    },

    methods: {
        //项目客户的选择
        selectTreeCustClick(node) {
            this.searchForm.customerCompanyId = node.id;
        },
        // 项目产品的选择
        selectSystem(data) {
            this.searchForm.systemsInvolvedId = data.value;
        },
        //获取选中数据
        getCurrentRow() {
            this.templateSelection = this.tableData[this.radio];
        },
        // 保存
        save() {
            if (this.radio === "") {
                this.$message({ message: "你还没有选择数据！", type: "error" });
                return;
            }
            this.getCurrentRow();
            this.$emit("close", this.templateSelection);
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 获取储备项目列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".miniTable"),
            });
            let data = Object.assign({}, this.searchForm);
            API.selectReserveProjectList(data)
                .then((res) => {
                    loading.close();
                    this.tableData = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.miniTable ::v-deep .el-radio__label {
    display: none;
}
.footerControl {
    text-align: right;
}
.footerControl .el-button {
    margin: 0 15px;
}
.miniTable {
    overflow: auto;
}
</style>