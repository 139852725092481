// 项目管理详情
// 项目进行阶段:1-项目规划,2-实施阶段,3-竣工验收,4-项目核算,5-归档
// 项目状态:1-进行中,2-搁置,3-废止,4-完成

<template>

    <div class='projectManageDetails'>
        <!-- 返回上级 -->
        <div style="padding:20px 20px 5px"
             class="ofh">
            <backUp class="fll"
                    :isNeedTip="true"
                    :to="backUpPath"></backUp>
            <div class="flr topControlBar"
                 v-if="!disable">
                <span class="el-icon-folder-opened"
                      v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_查看')"
                      @click="openpopFilesHistory"></span>
                <span class="el-icon-s-custom"
                      v-if="commonJs.isShowBtn(detailRole, 'Btn_成员管理_查看')"
                      @click="openpopPersonItemPowerManage"></span>
            </div>
        </div>

        <div class="contentWarp"
             :style="{height: Height+'px'}">

            <!-- 头部内容 -->
            <div class="header ofh"
                 v-if="projectDetails">
                <!-- <p class="itemName fll"
                   v-show="isEditName === false">
                    {{projectDetails.name}}
                    <el-link type="primary">
                        <span class="el-icon-edit-outline"
                              style="font-size: 20px;"
                              @click="isEditName = true"></span>
                    </el-link>
                </p> -->
                <div class="itemName fll"
                     style="width: 400px">
                    <span class="red">*</span>
                    <el-input v-model="projectDetails.name"
                              placeholder="请输入项目名"
                              size="normal"></el-input>
                </div>

                <div class="stepBox flr">
                    <el-steps :active="nowStep"
                              space="170px">
                        <el-step title="项目规划"
                                 :class="{
                                      'stepIng': stepInfo[1-1].status === '2',
                                      'stepOver': stepInfo[1-1].status === '3',
                                      'stepNotOver': stepInfo[1-1].status === '1'
                                }"
                                 @click.native="nextStep(0)"></el-step>
                        <el-step title="实施阶段"
                                 :class="{
                                      'stepIng': stepInfo[2-1].status === '2',
                                      'stepOver': stepInfo[2-1].status === '3',
                                      'stepNotOver': stepInfo[2-1].status === '1'
                                }"
                                 @click.native="nextStep(1)"></el-step>
                        <el-step title="竣工验收"
                                 :class="{
                                      'stepIng': stepInfo[3-1].status === '2',
                                      'stepOver': stepInfo[3-1].status === '3',
                                      'stepNotOver': stepInfo[3-1].status === '1'
                                }"
                                 @click.native="nextStep(2)"></el-step>
                        <el-step title="项目核算"
                                 :class="{
                                      'stepIng': stepInfo[4-1].status === '2',
                                      'stepOver': stepInfo[4-1].status === '3',
                                      'stepNotOver': stepInfo[4-1].status === '1'
                                }"
                                 @click.native="nextStep(3)"></el-step>
                        <el-step title="归档"
                                 :class="{
                                      'stepIng': stepInfo[5-1].status === '2',
                                      'stepIng': stepInfo[5-1].status === '3',
                                      'stepNotOver': stepInfo[5-1].status === '1'
                                }"></el-step>
                    </el-steps>
                </div>
            </div>

            <el-row :gutter="20">
                <el-col :span="status!='新增' ?  12 : 24">
                    <!-- 项目规划 -->
                    <div class="step1"
                         v-show="nowStep + 1 === 1">
                        <el-row>
                            <el-col :span="24">
                                <!-- 项目信息 -->
                                <com-item-info ref="baseInfo"
                                               :disable="disable"
                                               :isEditMoney="isEditMoney"
                                               :projectDetails="projectDetails"></com-item-info>
                                <template v-if="!disable">
                                    <!-- 已完成 只显示保存按钮 -->
                                    <template v-if="isNowSetpOver">
                                        <el-footer class="footer"
                                                   v-if="projectDetails.stage != 5 && projectDetails.status != 2">
                                            <div class="flr">
                                                <el-button type="success"
                                                           size="mini"
                                                           icon="el-icon-upload"
                                                           v-show="nowStep != 3"
                                                           v-if="commonJs.isShowBtn(detailRole,'Btn_保存')"
                                                           @click="save(1)">保存</el-button>
                                            </div>
                                        </el-footer>
                                    </template>
                                    <template v-else>
                                        <el-footer class="footer"
                                                   v-if="projectDetails.stage != 5 && projectDetails.status != 2">
                                            <div class="flr">
                                                <el-button type="success"
                                                           size="mini"
                                                           icon="el-icon-upload"
                                                           v-show="nowStep != 3"
                                                           v-if="commonJs.isShowBtn(detailRole,'Btn_保存')"
                                                           @click="save(1)">保存</el-button>
                                            </div>
                                        </el-footer>
                                    </template>
                                </template>
                            </el-col>
                        </el-row>

                        <el-row v-if="status!='新增'">
                            <el-col :span="24">
                                <!-- 收款合同 -->
                                <com-contract title="收款合同"
                                              :disable="disable"
                                              :contractTotalMoney="contractTotalMoney"
                                              :contractList="contractList"
                                              :isDisableUpload="isDisableUpload"
                                              @update="update"></com-contract>
                            </el-col>
                        </el-row>

                        <template v-if="projectDetails.supplemenFlag!='否'">
                            <el-row v-if="status!='新增'">
                                <el-col :span="24">
                                    <!-- 关联增补项目 -->
                                    <com-link-item :resourceId="detailsId"
                                                   :disable="disable"
                                                   :projectDetails="projectDetails"
                                                   :linkItemList="linkItemList"
                                                   :isDisableUpload="isDisableUpload"
                                                   :supplementTotalMoney="supplementTotalMoney"
                                                   :supplementRemainMoney="supplementRemainMoney"
                                                   @update="update"></com-link-item>
                                </el-col>
                            </el-row>
                        </template>

                        <template v-if="projectDetails.supplemenFlag!='是'">
                            <el-row v-if="status!='新增'">
                                <el-col :span="24">
                                    <!-- 被关联增补项目 -->
                                    <com-link-itemed :resourceId="detailsId"
                                                     :disable="disable"
                                                     :projectDetails="projectDetails"
                                                     :linkItemList="projectSupplementRelevanceQuiltVoList"
                                                     :supplementQuiltTotalMoney="supplementQuiltTotalMoney"
                                                     @update="update"></com-link-itemed>
                                </el-col>
                            </el-row>
                        </template>

                        <el-row v-if="status!='新增'">
                            <el-col :span="24">
                                <!-- 文件 projectPlanStageFile -->
                                <com-file-list modelName="项目管理"
                                               :refreshList="refreshList"
                                               :disable="disable"
                                               :resourceId="detailsId"
                                               :isDisableUpload="isDisableUpload"
                                               :detailRole="detailRole"
                                               :fileTypeList="projectPlanStageFile"></com-file-list>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
                <el-col :span="12">
                    <!-- 项目规划 -->
                    <div class="step1"
                         v-show="nowStep + 1 === 1">
                        <el-row v-if="status!='新增'">
                            <el-col :span="24"
                                    class="mb20">
                                <!-- 干系人信息 -->
                                <com-person-info :resourceId="detailsId"
                                                 :disable="disable"
                                                 :detailRole="detailRole"
                                                 :isDisableUpload="isDisableUpload"
                                                 :openTreeNode="{id: projectDetails.companyId, name: projectDetails.customerName}"
                                                 :colData="['姓名', '电话号码', '公司名称','项目角色', '备注']"
                                                 @updatePerson="updatePerson"
                                                 :companyLinkInfoList="companyLinkInfoList"></com-person-info>
                            </el-col>
                            <el-col :span="24"
                                    class="mb20">
                                <!-- 跟进记录 -->
                                <com-follow-record class="reserveProjectfollowRecord"
                                                   ref="projectfollowRecord"
                                                   @update="update"
                                                   :reserveProjectId="detailsId"
                                                   :projectDetails="projectDetails"
                                                   type="PROJECT"
                                                   :disable="disable"></com-follow-record>
                            </el-col>
                            <el-col :span="24">
                                <!-- 项目规划 -->
                                <div class="step1"
                                     v-show="nowStep + 1 === 1">
                                    <el-row v-if="status!='新增'">
                                        <el-col :span="24">
                                            <!-- 实施计划 -->
                                            <com-implementation-plan :taskList="taskList"
                                                                     :disable="disable"
                                                                     :resourceId="detailsId"
                                                                     :detailRole="detailRole"
                                                                     :isDisableUpload="isDisableUpload"
                                                                     :control="['新增', '查看', '编辑', '删除', '废止']"
                                                                     @update="update"></com-implementation-plan>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>

            <!-- 实施阶段 -->
            <div class="step2"
                 v-if="status!='新增'"
                 v-show="nowStep + 1 === 2">
                <el-row>
                    <el-col :span="24">
                        <!-- 实施计划-表格 -->
                        <com-implementation-plan :taskList="taskList"
                                                 :disable="disable"
                                                 :control="['查看']"
                                                 @update="update"></com-implementation-plan>
                    </el-col>
                </el-row>

                <el-row v-if="status!='新增'">
                    <el-col :span="24">
                        <!-- 设备采购管理-表格 -->
                        <com-purchase-manage-table :deviceBuyList="deviceBuyList"
                                                   :disable="disable"
                                                   :isDisableUpload="isDisableUpload"
                                                   :projectDetails="projectDetails"
                                                   @update="getDeviceBuyList"
                                                   :detailsId="detailsId"></com-purchase-manage-table>
                    </el-col>
                </el-row>

                <el-row v-if="status!='新增'">
                    <el-col :span="24">
                        <!-- 部署管理-表格 -->
                        <com-table-deployment-manage :deviceBuyList="deviceBuyList"
                                                     :disable="disable"
                                                     :isDisableUpload="isDisableUpload"
                                                     @updateDeviceList="getDeviceBuyList"
                                                     :detailsId="detailsId"></com-table-deployment-manage>
                    </el-col>
                </el-row>

                <el-row v-if="status!='新增'">
                    <el-col :span="24">
                        <!-- 文件 projectCarryOutStageFile -->
                        <com-file-list modelName="项目管理"
                                       :refreshList="refreshList"
                                       :disable="disable"
                                       :resourceId="detailsId"
                                       :isDisableUpload="isDisableUpload"
                                       :detailRole="detailRole"
                                       :fileTypeList="projectCarryOutStageFile"></com-file-list>
                    </el-col>
                </el-row>

                <el-row v-if="status!='新增'">
                    <el-col :span="24">
                        <!-- 合同 -->
                        <com-contract title="施工队/采购合同"
                                      :disable="disable"
                                      @update="update"
                                      :contractTotalMoney="contractTotalMoneyOut"
                                      :isDisableUpload="isDisableUpload"
                                      :contractList="payContractList"></com-contract>
                    </el-col>
                </el-row>
            </div>

            <!-- 竣工验收 -->
            <div class="step3"
                 v-if="status!='新增'"
                 v-show="nowStep + 1 === 3">
                <el-row>
                    <el-col :span="24">
                        <!-- 文件 projectAcceptanceStageFile -->
                        <com-file-list-with-contract modelName="项目管理"
                                                     :refreshList="refreshList"
                                                     :disable="disable"
                                                     :isDisableUpload="isDisableUpload"
                                                     :contractList="contractList"
                                                     v-if="contractList && contractList.length"
                                                     :detailRole="detailRole"
                                                     :fileTypeList="projectAcceptanceStageFile"></com-file-list-with-contract>
                        <div class="emptyBg notBindContract"
                             v-else>请先绑定收款合同，根据合同上传对应的资料</div>
                    </el-col>
                </el-row>
            </div>

            <!-- 项目核算 -->
            <div class="step4"
                 v-if="status!='新增'"
                 v-show="nowStep + 1 === 4">
                <el-row>
                    <el-col :span="24">
                        <!-- 文件 projectCheckStageFile -->
                        <com-file-list modelName="项目管理"
                                       :refreshList="refreshList"
                                       :disable="disable"
                                       :resourceId="detailsId"
                                       :isDisableUpload="isDisableUpload"
                                       :detailRole="detailRole"
                                       :fileTypeList="projectCheckStageFile"></com-file-list>
                    </el-col>
                </el-row>
            </div>

        </div>
        <template v-if="!disable">
            <!-- 已完成 只显示保存按钮 -->
            <template v-if="!isNowSetpOver">
                <!-- 标准 -->
                <el-footer class="footer"
                           v-if="projectDetails.stage != 5 && projectDetails.status != 2">
                    <div class="flr">
                        <template v-if="status!='新增'">
                            <template v-if="!isJump">
                                <el-button size="mini"
                                           v-show="nowStep != 3"
                                           v-if="commonJs.isShowBtn(detailRole,'Btn_跳过')"
                                           @click="save(3)">跳过</el-button>
                            </template>
                        </template>
                        <template v-if="!isNowSetpOver && status!='新增'">
                            <el-button type="primary"
                                       size="mini"
                                       v-show="nowStep != 3"
                                       v-if="commonJs.isShowBtn(detailRole,'Btn_完成本环节')"
                                       @click="save(2)">完成本环节</el-button>
                        </template>
                        <el-button type="primary"
                                   size="mini"
                                   v-show="nowStep == 3"
                                   v-if="commonJs.isShowBtn(detailRole,'Btn_项目归档')"
                                   @click="save(2)">项目归档</el-button>

                    </div>
                </el-footer>

            </template>
        </template>

        <!-- 文件历史 -->
        <pop-files-history v-if="popFilesHistoryVisible"
                           :resourceId="detailsId"
                           :fileManage="fileManage"
                           :contractList="contractList"
                           @update="update"
                           :detailRole="detailRole"
                           type="项目管理"
                           moduleType="PROJECT"
                           :disableUpload="isDisableUpload"
                           @close="closepopFilesHistory"></pop-files-history>
        <!-- 项目人员权限管理 -->
        <pop-person-item-power-manage v-if="popPersonItemPowerManageVisible"
                                      :resourceId="detailsId"
                                      :detailRole="detailRole"
                                      @close="closepopPersonItemPowerManage"></pop-person-item-power-manage>

    </div>

</template>

<script>
import ComFollowRecord from "../reserveProject/components/comFollowRecord";
import { mapGetters } from "vuex";
import ComTableDeploymentManage from "./components/comTableDeploymentManage";
import ComPersonInfo from "../reserveProject/components/comPersonInfo/comPersonInfo";
import comLinkItem from "./components/comLinkItem.vue";
import comLinkItemed from "./components/comLinkItemed.vue";
import comContract from "./components/comContract.vue";
import comFileList from "../implementationManage/components/comFileList";
import comFileListWithContract from "../implementationManage/components/comFileListWithContract";
import popFilesHistory from "../implementationManage/components/popFilesHistory";
import comPurchaseManageTable from "./components/comPurchaseManageTable.vue";
import comImplementationPlan from "./components/comImplementationPlan.vue";
import comItemInfo from "./components/comItemInfo.vue";
import popPersonItemPowerManage from "./components/popPersonItemPowerManage.vue";
import backUp from "@/components/backUp";
import API from "@/api/projectManage.js";
import notNeedFilterRoleAPI from "@/api/notNeedFilterRole.js";
import commonAPI from "@/api/commonAPI.js";
import { Loading } from "element-ui";

export default {
    name: "projectManageDetails",

    props: [],

    components: {
        ComFollowRecord,
        ComTableDeploymentManage,
        ComPersonInfo,
        backUp,
        "pop-person-item-power-manage": popPersonItemPowerManage,
        "com-item-info": comItemInfo,
        "pop-files-history": popFilesHistory,
        "com-implementation-plan": comImplementationPlan,
        "com-file-list": comFileList,
        "com-file-list-with-contract": comFileListWithContract,
        "com-purchase-manage-table": comPurchaseManageTable,
        "com-contract": comContract,
        "com-link-item": comLinkItem,
        "com-link-itemed": comLinkItemed,
    },

    data() {
        return {
            status: this.$route.query.status || null, // 当前页面是编辑还是查看
            detailsId: this.$route.query.id || null, // 编辑的id
            step: this.$route.query.step || null, // 环节名称
            popCreatItemVisible: false, // 生成项目弹窗显示
            popFilesHistoryVisible: false, // 文件历史弹窗显示
            popPersonItemPowerManageVisible: false, // 项目人员权限管理弹窗显示
            isEditName: false, // 是否在修改项目名称
            Height: 0,

            projectDetails: {
                // 基础信息
                name: "",
                supplemenFlag: "否", // 是否增补项目
                belongDeptId: null, // 归属部门
                stage: "", //
                status: "", //
            },
            companyLinkInfoList: null, // 干系人信息
            contractList: [], // 合同信息
            contractTotalMoney: 0, // 总金额
            contractTotalMoneyOut: 0, // 付款合同金额
            stepInfo: [
                // 节点信息
                {
                    code: "1",
                    status: "1",
                },
                {
                    code: "2",
                    status: "",
                },
                {
                    code: "3",
                    status: "",
                },
                {
                    code: "4",
                    status: "",
                },
                {
                    code: "5",
                    status: "",
                },
            ],
            stepIng: 0, // 正在进行中的节点index
            nowStep: 0, //当前进度
            taskList: null, // 实施计划
            projectPlanStageFile: [], // 项目规划 文件
            projectCarryOutStageFile: [], // 实施阶段 文件
            projectAcceptanceStageFile: [], // 竣工验收 文件
            linkItemList: [], // 关联的增补项目
            projectCheckStageFile: [], // 项目核算 文件
            payContractList: [], // 实施阶段-付款合同
            deviceBuyList: null, // 设备采购管理列表
            fileManage: [], // 文件管理模块字典
            detailRole: {}, //权限数据
            backUpPath: "", // 返回上一级
            // 强制刷新
            refreshList: "",
            isEditMoney: true, // 是否可以编辑金额
            supplementTotalMoney: 0, // 分配金额总计
            supplementRemainMoney: 0, // 剩余分配金额总计
            supplementQuiltTotalMoney: 0, // 被增补项目金额总计
            projectSupplementRelevanceQuiltVoList: [], // 被增补项目合同
            // 全局禁用
            disable: this.$route.query.disable
                ? Boolean(this.$route.query.disable)
                : false,
        };
    },

    created() {},

    mounted() {
        if (this.$route.query.disable) {
            this.$set(this, "disable", Boolean(this.$route.query.disable));
        } else {
            this.$set(this, "disable", false);
        }
        if (window.localStorage.getItem("toProject")) {
            this.backUpPath = window.localStorage.getItem("toProject");
        }
        //动态设置内容高度 让footer始终居底   header+footer的高度是184
        this.Height = document.documentElement.clientHeight - 184; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 184;
        };
        if (!this.detailsId) {
            // 获取新增的Id
            this.getId();
            this.isEditName = true;
        } else {
            // 获取最新数据
            this.update().then(() => {
                let stepingFilter = this.stepInfo.filter(
                    (item) => item.status === "2"
                );
                this.stepIng =
                    stepingFilter.length > 0
                        ? stepingFilter[stepingFilter.length - 1].code - 1
                        : 0;
                // 都完成了的要单独处理
                if (
                    this.stepInfo.every((item) => String(item.status) === "3")
                ) {
                    this.stepIng = 3;
                }
                this.nowStep = this.stepIng;
            });
            // 获取项目角色列表
            this.getProjectRole();
        }
    },

    methods: {
        // 更新跟进记录中的跟进对象
        updatePerson() {
            this.$refs.projectfollowRecord.getFollowObj();
        },
        // 头部文件管理
        getModuleDict() {
            let loading = Loading.service({
                target: document.querySelector(".topControlBar"),
            });
            commonAPI
                .getModuleDict("PROJECT")
                .then((res) => {
                    loading.close();
                    this.fileManage = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取项目角色列表
        getProjectRole() {
            let loading = Loading.service({
                target: document.querySelector(".comImplementationPlanTable"),
            });
            API.getProjectRole({
                menuId: "1332274331730391041",
                projectId: this.detailsId,
            })
                .then((res) => {
                    loading.close();
                    // 项目经理会有两个数组，但是成员只有一个
                    if (res.content && res.content.length > 1) {
                        let data = {
                            buttonMenus: [],
                            detailMenu: {},
                        };
                        res.content.forEach((item) => {
                            data.buttonMenus = [
                                ...data.buttonMenus,
                                ...item.buttonMenus,
                            ];
                            data.detailMenu = {
                                ...data.detailMenu,
                                ...item.buttonMenus,
                            };
                        });
                        this.detailRole = data;
                        return;
                    }
                    this.detailRole = res.content[0] || null;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 查询项目下的部署采购管理
        getDeviceBuyList() {
            let loading = Loading.service({
                target: document.querySelector(".comImplementationPlanTable"),
            });
            notNeedFilterRoleAPI
                .purchaseOrderLis({
                    projectId: this.detailsId,
                })
                .then((res) => {
                    loading.close();
                    this.deviceBuyList = res.content.records;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取文件字典项列表
        getModuleCode(code) {
            let loading = Loading.service({
                target: document.querySelector(".comfileBox"),
            });
            commonAPI
                .getModuleCode(code)
                .then((res) => {
                    loading.close();
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            resourceType: val["value"],
                            redOrBlue: val["defaultFlag"] ? "red" : "blue",
                            title: val["name"],
                        };
                    });
                    this[code] = opt;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 切换进度条
        nextStep(step) {
            if (step <= this.stepIng) {
                this.nowStep = step;
            }
        },
        // 文件历史 打开弹框
        openpopFilesHistory() {
            this.popFilesHistoryVisible = true;
        },
        // 文件历史 关闭弹框
        closepopFilesHistory() {
            this.popFilesHistoryVisible = false;
        },
        // 项目人员权限管理 打开弹框
        openpopPersonItemPowerManage() {
            this.popPersonItemPowerManageVisible = true;
        },
        // 项目人员权限管理 关闭弹框
        closepopPersonItemPowerManage() {
            this.popPersonItemPowerManageVisible = false;
        },
        // 生成项目 打开弹框
        openpopCreatItem() {
            this.popCreatItemVisible = true;
        },
        // 生成项目 关闭弹框
        closepopCreatItem() {
            this.popCreatItemVisible = false;
        },
        // 完成项目点击效验
        overItemBtn() {
            this.$confirm(
                "当前项目机会有关联的项目机会没有完成，无法完成！",
                "完成项目机会",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {
                this.handleOverItem();
            });
        },
        // 完成项目操作
        handleOverItem() {
            this.$confirm(
                "完成项目机会后项目机会信息将不可修改！是否确认完成。",
                "完成项目机会",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {});
        },
        // 继续跟进
        continueItemBtn() {
            this.$confirm("是否继续跟进此项目机会！", "继续跟进", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {});
        },
        // 获取新增项目的Id
        getId() {
            let loading = Loading.service({
                target: document.querySelector(".projectManageDetails"),
            });
            API.getId()
                .then((res) => {
                    loading.close();
                    this.detailsId = res.content;
                    this.update();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取项目详情
        getDetails() {
            return new Promise((resolve, reject) => {
                let loading = Loading.service({
                    target: document.querySelector(".contentWarp"),
                });
                API.getDetails(this.detailsId)
                    .then((res) => {
                        loading.close();
                        // 基础信息
                        this.projectDetails = res.content.project;
                        // 合同信息
                        this.contractList = res.content.contractInList;
                        // 付款合同
                        this.payContractList = res.content.contractOutList;
                        // 收款合同金额
                        this.contractTotalMoney =
                            res.content.contractTotalMoney;
                        // 被增补项目合同列表
                        this.projectSupplementRelevanceQuiltVoList =
                            res.content.projectSupplementRelevanceQuiltVoList;
                        // 分配金额总计
                        this.$set(
                            this,
                            "supplementTotalMoney",
                            res.content.supplementTotalMoney
                        );
                        // 剩余分配金额总计
                        this.$set(
                            this,
                            "supplementRemainMoney",
                            res.content.supplementRemainMoney
                        );
                        // 增补项目金额总计
                        this.$set(
                            this,
                            "supplementQuiltTotalMoney",
                            res.content.supplementQuiltTotalMoney
                        );
                        // 是否可以编辑金额
                        this.isEditMoney =
                            res.content.isEditMoney === "是" ? true : false;
                        // 付款合同金额
                        this.contractTotalMoneyOut =
                            res.content.contractTotalMoneyOut;
                        // 关联的增补项目
                        this.linkItemList =
                            res.content.projectSupplementRelevanceVoList;
                        // 节点信息
                        this.stepInfo =
                            res.content.projectNodeRelevanceActionList;
                        // 实施计划
                        this.taskList = res.content.taskList;
                        resolve();
                    })
                    .catch(() => {
                        loading.close();
                        reject();
                    });
            });
        },
        // 保存
        save(status) {
            if (
                !this.projectDetails.companyId ||
                !this.projectDetails.name ||
                !this.projectDetails.supplemenFlag ||
                !this.projectDetails.managerId ||
                !this.projectDetails.systemId ||
                !this.projectDetails.belongDeptId ||
                !this.projectDetails.planFinishTime
            ) {
                this.$message({
                    message: "请检查必填信息是否填写完整！",
                    type: "error",
                });
                return false;
            }
            if (
                this.projectDetails.startTime &&
                this.projectDetails.planFinishTime
            ) {
                if (
                    this.$moment(this.projectDetails.startTime) >
                    this.$moment(this.projectDetails.planFinishTime)
                ) {
                    this.$message({
                        message: "项目计划交付日期不能小于项目开始时间",
                        type: "error",
                    });
                    return;
                }
            }
            let loading = Loading.service({
                target: document.querySelector(".projectManageDetails"),
            });
            let data = {
                project: {
                    id: this.detailsId,
                    ...this.projectDetails,
                    status: this.projectDetails.status || "1",
                },
                saveOrFinish: status || "", // 1-保存(不需要必填验证)，2-完成(需要必填验证)，3-跳过,4-搁置，5-废除
            };
            data.project.stage = this.nowStep + 1;
            API.saveProject(data)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    if (this.status === "新增") {
                        this.$router.push({
                            path: "/projectManage/projectManageDetails",
                            query: {
                                status: "编辑",
                                id: this.detailsId,
                            },
                        });
                    } else {
                        if (String(status) !== "1") {
                            if (Number(this.nowStep) < 3) {
                                this.nowStep++;
                            }
                        }
                        // 更新数据
                        this.update();
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 废止
        abolish() {
            this.$confirm(
                "废止项目将被归档，不可恢复，不可编辑",
                "确认要废止项目吗",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                }
            ).then(() => {
                this.save(5);
            });
        },
        // 搁置
        shelve() {
            this.$confirm(
                "搁置项目将保留当前进度，可在重启后继续进行",
                "确认要搁置项目吗？",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {
                this.save(4);
            });
        },
        // 更新数据
        update() {
            return new Promise((resolve) => {
                if (this.status !== "新增") {
                    // 获取项目详情
                    this.getDetails().then(() => {
                        resolve();
                    });
                }
                // 头部文件管理
                this.getModuleDict();
                this.refreshList = Math.random();
                if (this.nowStep === 0) {
                    // 获取文件字典项列表
                    this.getModuleCode("projectPlanStageFile"); // 项目规划
                } else if (this.nowStep === 1) {
                    // 获取文件字典项列表
                    this.getModuleCode("projectCarryOutStageFile"); // 实施阶段
                    // 查询项目下的设备采购管理
                    this.getDeviceBuyList();
                } else if (this.nowStep === 2) {
                    // 获取文件字典项列表
                    this.getModuleCode("projectAcceptanceStageFile"); // 竣工验收
                } else if (this.nowStep === 3) {
                    // 获取文件字典项列表
                    this.getModuleCode("projectCheckStageFile"); // 竣工验收
                }
            });
        },
    },

    computed: {
        ...mapGetters(["userInfo"]),
        // 是否禁止编辑上传
        isDisableUpload() {
            let flag = false;
            // 归档和完成
            if (
                this.projectDetails.stage === "5" ||
                this.projectDetails.status === "2"
            ) {
                flag = true;
            }
            return flag;
        },
        // 是否已经完成 true是已完成
        isNowSetpOver() {
            return String(this.stepInfo[this.nowStep].status) === "3"
                ? true
                : false;
        },
        // 是否是跳过 true是跳过
        isJump() {
            return String(this.stepInfo[this.nowStep].isJump) === "是"
                ? true
                : false;
        },
    },

    watch: {
        nowStep() {
            this.update();
        },
        "userInfo.deptId": {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if (!this.detailsId) {
                    this.projectDetails.belongDeptId = newVal;
                }
            },
        },
    },

    beforeRouteEnter(to, from, next) {
        if (from.meta.title === "归档项目") {
            window.localStorage.setItem(
                "toProject",
                "/projectManage/archivedItems"
            );
        } else if (from.meta.title === "项目管理") {
            window.localStorage.setItem("toProject", "/projectManage/index");
        } else if (from.meta.title === "供应商信息管理") {
            window.localStorage.setItem(
                "toProject",
                "/baseInfoManage/supplierInfoManages"
            );
        } else {
            window.localStorage.setItem("toProject", null);
        }
        next();
    },
};
</script>

<style lang='scss' scoped>
.projectManageDetails {
    padding-bottom: 0;
}
.header {
    overflow: hidden;
    line-height: 50px;
    margin-bottom: 15px;
    background: #ffffff;
}
.header .itemName {
    float: left;
    font-size: 22px;
    font-weight: 500;
    border-radius: 5px;
    padding: 0 15px;
    margin: 0;
    position: relative;
}
.header .itemName .red {
    font-size: 20px;
    position: absolute;
    top: -10px;
    left: 4px;
}
.header .address {
    margin-left: 15px;
    float: left;
}
.header .el-radio-group {
    float: left;
    margin-left: 55px;
    margin-top: 5px;
}
.header .status {
    font-size: 22px;
}
// 进度条
.header .stepBox {
    width: 800px;
}
.header ::v-deep .el-step__icon {
    width: 30px;
    height: 30px;
    border-color: #d9d9d9;
    color: #d9d9d9;
    position: relative;
}
.header ::v-deep .el-step__icon:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #fff;
    position: absolute;
    top: 5px;
    left: -17px;
}
// 进行中的
.header ::v-deep .is-process .el-step__icon {
    background: #1990ff;
    border-color: #1990ff;
    color: #ffffff;
}
.header ::v-deep .el-step__title.is-process {
    color: #1990ff;
}
// 已完成的
.header ::v-deep .is-finish .el-step__icon {
    border-color: #1990ff;
    color: #1990ff;
}
.header ::v-deep .el-step.is-horizontal .el-step__line {
    top: 24px;
}
.header ::v-deep .el-step__main {
    white-space: normal;
    text-align: left;
    position: absolute;
    top: 6px;
    left: 30px;
    background: #ffffff;
    padding: 0 12px;
    color: #d9d9d9;
    cursor: pointer;
}

.el-row {
    margin-bottom: 20px;
}
.projectManageDetails .contentWarp {
    padding: 20px;
    background: #f2f5f8;
    overflow: scroll;
}
.projectManageDetails > .footer {
    background: #ffffff;
    padding: 8px 15px;
    width: 100%;
    height: 40px !important;
}

// 头部控制按钮
.topControlBar span {
    font-size: 24px;
    cursor: pointer;
    margin: 0 15px;
    color: #909399;
    transition: all 0.5s;
}
.topControlBar span:hover {
    transition: all 0.5s;
    color: #409eff;
}
.notBindContract {
    text-align: center;
    height: 300px;
    background-color: #fff;
    border-radius: 5px;
    line-height: 500px;
    color: #f00;
}
.step1 .footer {
    background: #fff;
    padding-top: 15px;
}
</style>
