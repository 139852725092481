// 实施阶段 - 设备采购管理
<template>

    <div class="comImplementationPlanTable">
        <el-card>
            <div slot="header">设备采购管理</div>
            <div class="miniTable">
                <el-table :data="deviceBuyList"
                          border
                          stripe>
                    <el-table-column label="序号"
                                     type="index"
                                     align="center"
                                     width="100">
                    </el-table-column>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     align="center"
                                     :min-width="col.width">
                    </el-table-column>
                    <el-table-column prop="id"
                                     label="操作"
                                     align="center"
                                     min-width="100">
                        <template slot-scope="scope">
                            <!-- 跳转关闭 -->
                            <el-link type="primary"
                                     @click="openpopDevicePurchaseHistory(scope.row)">查看</el-link>
                            <template v-if="scope.row.inStatus.name">
                                <el-link type="primary"
                                         :underline="false"
                                         v-if="scope.row.inStatus.name !== 'FINISH'"
                                         @click="openaddPurchase(scope.row)">编辑</el-link>
                                <el-link type="danger"
                                         v-if="scope.row.inStatus.name !== 'FINISH'"
                                         @click="openpopDelPurchase(scope.row)">删除</el-link>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>

                <template v-if="!disable">
                    <div class="addBar"
                         v-if="!isDisableUpload"
                         @click="openaddPurchase()">
                        + 新增设备采购
                    </div>
                </template>
                <div class="totalBar ofh">
                    <i class="blue el-icon-warning mr10"></i>
                    <span v-if="deviceBuyList">已添加<i>{{deviceBuyList.length}}</i>项</span>
                    <span>采购数量总计：<i class="blue">{{counts.empCount}}</i>件</span>
                    <span>入库数量总计：<i class="blue">{{counts.inCount}}</i>件</span>
                    <div class="flr">
                        <el-button type="primary"
                                   v-if="deviceBuyList.length>0"
                                   size="mini"
                                   @click="openpopAllPurchaseList">查看采购设备总表</el-button>
                    </div>
                </div>
            </div>
        </el-card>

        <!-- 新增采购 -->
        <pop-add-purchase v-if="addPurchaseVisible"
                          :detailsId="detailsId"
                          :isNeedTask="true"
                          :projectDetails="projectDetails"
                          :editPurchaseInfo="editPurchaseInfo"
                          @update="update"
                          @close="closeaddPurchase"></pop-add-purchase>
        <!-- 设备采购记录总表 -->
        <pop-all-purchase-List v-if="popAllPurchaseVisible"
                               :detailsId="detailsId"
                               @close="closepopAllPurchaseList"></pop-all-purchase-List>
        <!-- 删除采购单 -->
        <pop-del-purchase v-if="popDelPurchaseVisible"
                          :delPurchaseId="delPurchaseId"
                          :delNeedDescription="delNeedDescription"
                          @delPurchase="handleDelPurchase"
                          @close="closepopDelPurchase"></pop-del-purchase>

    </div>

</template>

<script>
import PopDelPurchase from "./popDelPurchase";
import PopAddPurchase from "../../purchaseManage/components/popAddPurchase"; // 新增采购
import popAllPurchaseList from "./popAllPurchaseList";
import { Loading } from "element-ui";
import API from "@/api/purchaseManage.js";
export default {
    name: "comPurchaseManageTable",

    props: {
        deviceBuyList: {
            // 设备采购管理列表
            type: Array,
            default() {
                return [];
            },
        },
        detailsId: {
            // 项目Id
            type: [String, Number],
            default: "",
        },
        isDisableUpload: {
            // 是否禁止上传或保存 true==禁止 false==允许
            type: Boolean,
            default: true,
        },
        // 全局禁用
        disable: {
            type: Boolean,
            default: false,
        },
        // 项目详情
        projectDetails: {
            type: Object,
            default: null,
        },
    },

    components: {
        PopDelPurchase,
        PopAddPurchase,
        "pop-all-purchase-List": popAllPurchaseList,
    },

    data() {
        return {
            addPurchaseVisible: false, // 新增采购弹窗显示开关
            popDevicePurchaseHistoryVisible: false, // 设备采购记录弹窗显示
            popAllPurchaseVisible: false, // 设备采购记录总表弹窗
            devicePurchaseItem: null, // 设备采购记录选中项
            editPurchaseInfo: null, // 编辑采购的id
            tableData: {
                columns: [
                    { id: "code", label: "采购编号", width: "100" },
                    { id: "purchaseDate", label: "采购日期", width: "100" },
                    {
                        id: "purchasePersonName",
                        label: "采购负责人",
                        width: "100",
                    },
                    { id: "empCount", label: "设备数量", width: "100" },
                    { id: "inStatus", label: "入库状态", width: "100" },
                    { id: "inCount", label: "入库数量", width: "100" },
                ],
            },
            popDelPurchaseVisible: false, // 删除采购单弹窗显示
            delPurchaseId: null, // 删除采购单的id
            delNeedDescription: true, // 删除采购单是否需要描述
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 更新数据
        update() {
            this.$emit("update");
        },
        // 删除采购单 打开弹框
        openpopDelPurchase(row) {
            this.delPurchaseId = row.id;
            this.delNeedDescription = row.inCount > 0 ? true : false;
            this.popDelPurchaseVisible = true;
        },
        // 删除采购单 关闭弹框
        closepopDelPurchase() {
            this.delPurchaseId = null;
            this.delNeedDescription = true;
            this.popDelPurchaseVisible = false;
        },
        // 触发删除采购
        handleDelPurchase(data) {
            let loading = Loading.service({
                target: document.querySelector(".miniTable"),
            });
            API.delPurchase(data)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.$emit("update");
                    this.closepopDelPurchase();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 打开新增采购弹框
        openaddPurchase(row) {
            this.editPurchaseInfo = row || null;
            this.addPurchaseVisible = true;
        },
        // 关闭新增采购弹框
        closeaddPurchase() {
            this.editPurchaseInfo = null;
            this.addPurchaseVisible = false;
            this.$emit("updateDeviceList");
        },
        // 查看采购详情
        openpopDevicePurchaseHistory(item) {
            this.devicePurchaseItem = item;
            let routeData = this.$router.resolve({
                path: "/projectManage/purchaseDetails",
                query: { selected: item.id, needBackUp: false },
            });
            window.open(routeData.href, "_blank");
        },
        // 设备采购记录总表 打开弹框
        openpopAllPurchaseList() {
            this.popAllPurchaseVisible = true;
        },
        // 设备采购记录总表 关闭弹框
        closepopAllPurchaseList() {
            this.devicePurchaseItem = null;
            this.popAllPurchaseVisible = false;
        },
    },

    computed: {
        counts() {
            let counts = {
                empCount: 0, //采购数量
                inCount: 0, // 入库数量
            };
            if (this.deviceBuyList) {
                this.deviceBuyList.forEach((item) => {
                    counts.empCount += item.empCount;
                    counts.inCount += item.inCount;
                });
            }
            return counts;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.addBar {
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    background: #d8f4ff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #00a2e9;
    text-align: center;
    cursor: pointer;
}
// 合计栏
.totalBar {
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #9093b3;
    padding-left: 15px;
}
.totalBar span {
    margin-right: 15px;
}
</style>
