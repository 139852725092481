// 文件列表-带合同
<template>

    <div class="comfileBox">
        <el-card>
            <div slot="header">文件</div>
            <div class="typeItem">
                <div class="contractItem"
                     v-for="(item,idx) in fileTypeList"
                     :key="idx">
                    <div class="title mb15">
                        <span :class="item.redOrBlue+'circle'"></span>
                        <span>{{item.title}}</span>
                    </div>
                    <el-row :gutter="10"
                            class="contractRow"
                            v-for="(it,i) in contractList"
                            :key="i">
                        <el-col :span="7">
                            <div class="contractList">
                                <div class="contractItem"
                                     @click="toContractDetails(it.id, it.status.name)">
                                    <div class="titlt ellipsis">
                                        <span v-if="it.name && it.name.length<16">{{it.name}}</span>
                                        <el-tooltip effect="dark"
                                                    v-else
                                                    :content="it.name"
                                                    placement="top-start">
                                            <el-button>上左</el-button>
                                            <span>{{it.name}}</span>
                                        </el-tooltip>
                                    </div>
                                    <div class="pic">金额：{{it.projectMoney}}元</div>
                                    <div class="status"
                                         v-if="it.status">状态：{{it.status.text}}</div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <!-- 文件 -->
                            <com-file-upload-list :style="{height: '180px','padding-top':'10px','box-sizing':'border-box'}"
                                                  :showUpBtn="isCanUpload"
                                                  fileBoxHeight="130px"
                                                  :showDownloadBtn="showDownloadBtn"
                                                  :uploadData="{resourceId: resourceId ? resourceId : it.projectContractId, resourceType: item.resourceType}"
                                                  :refreshList="refreshList"
                                                  :neddLoadFile="true"
                                                  :justShowFistOne="true"
                                                  :showDelBtn="false"></com-file-upload-list>
                        </el-col>

                        <template v-if="!disable">
                            <el-col :span="5"
                                    v-if="!isDisableUpload">
                                <div class="controlBox">
                                    <!-- <el-button type="primary"
                                           size="mini"
                                           @click="">点击上传</el-button> -->
                                    <el-button type="success"
                                               size="mini"
                                               v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_下载文件')"
                                               @click="openpopDownLoadTemFile(item)">下载模板</el-button>
                                    <el-button type="danger"
                                               size="mini"
                                               v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_历史记录')"
                                               @click="openpopFileHistory(item)">历史记录</el-button>
                                </div>
                            </el-col>
                        </template>
                    </el-row>
                </div>
            </div>
        </el-card>

        <!-- 下载模板 -->
        <pop-download-tem-file v-if="popDownLoadTemFileVisible"
                               :modelName="modelName"
                               :dataRecommendTep="dataRecommendTep"
                               :resourceId="resourceId"
                               @close="closepopDownLoadTemFile"></pop-download-tem-file>
        <!-- 文件历史 -->
        <pop-file-history v-if="popFileHistoryVisible"
                          :resourceId="resourceId"
                          :fileHistoryType="fileHistoryType"
                          @close="closepopFileHistory"></pop-file-history>

    </div>

</template>

<script>
import comFileUploadList from "@/components/upload/comFileUploadList";
import popFileHistory from "../../../components/popFileHistory.vue";
import popDownloadTemFile from "../../../components/popDownloadTemFile.vue";
export default {
    name: "comfileBox",

    props: {
        // 模块名称 因为项目和实施比较特殊
        modelName: {
            type: String,
            default: null,
        },
        resourceId: {
            // 项目Id
            type: [String, Number],
            default: null,
        },
        fileTypeList: {
            // 多个的时候
            type: Array,
            default() {
                return [
                    {
                        resourceType: "TASK_SITE_SURVEY", // 文件类型
                        redOrBlue: "red", // 蓝点点还是红点点
                        title: "现场勘察记录", // 显示标题
                    },
                ];
            },
        },
        isDisableUpload: {
            // 是否禁止上传或保存 true==禁止 false==允许
            type: Boolean,
            default: true,
        },
        contractList: {
            // 合同列表
            type: Array,
            default() {
                return [];
            },
        },
        //是否需要强制刷新
        refreshList: {},
        detailRole: {
            type: Object,
        },
        // 全局禁用
        disable: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        "pop-download-tem-file": popDownloadTemFile,
        "pop-file-history": popFileHistory,
        "com-file-upload-list": comFileUploadList,
    },

    data() {
        return {
            popDownLoadTemFileVisible: false, // 下载模板弹窗显示
            popFileHistoryVisible: false, // 文件历史记录弹窗显示
            fileHistoryType: null, // 文件历史所需type
            dataRecommendTep: {}, // 下载模板所需data
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 跳转到合同详情
        toContractDetails() {
            // let data = {
            //     id,
            //     listType,
            //     isSeeDetail: true,
            // };
            // let routeData = this.$router.resolve({
            //     path: "/contractManage/contractManageDetail",
            //     query: { data: JSON.stringify(data) },
            // });
            // window.open(routeData.href, "_blank");
        },
        // 下载模板 打开弹框
        openpopDownLoadTemFile(item) {
            this.dataRecommendTep = item;
            this.popDownLoadTemFileVisible = true;
        },
        // 下载模板 关闭弹框
        closepopDownLoadTemFile() {
            this.popDownLoadTemFileVisible = false;
        },
        // 文件历史记录 打开弹框
        openpopFileHistory(item) {
            this.fileHistoryType = item;
            this.popFileHistoryVisible = true;
        },
        // 文件历史记录 关闭弹框
        closepopFileHistory() {
            this.fileHistoryType = null;
            this.popFileHistoryVisible = false;
        },
    },

    computed: {
        isCanUpload() {
            let flag = !this.isDisableUpload;
            // 全局禁用
            if (this.disable) {
                return false;
            }
            if (
                !this.commonJs.isShowBtn(
                    this.detailRole,
                    "Btn_文件管理_上传文件"
                )
            ) {
                flag = false;
            }
            return flag;
        },
        showDownloadBtn() {
            let flag = true;
            if (this.disable) {
                flag = false;
            }
            if (
                !this.commonJs.isShowBtn(
                    this.detailRole,
                    "Btn_文件管理_下载文件"
                )
            ) {
                return false;
            }
            return flag;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.typeItem {
    font-size: 14px;
    margin-bottom: 25px;
    border-bottom: 1px solid #eee;
    padding: 15px 0;
}
.typeItem .nameBox .bluecircle {
    vertical-align: middle;
    width: 8px;
    height: 8px;
    display: inline-block;
    background: url("../../../assets/imgs/icon_blueCircle.png") no-repeat center
        center;
    background-size: 8px 8px;
    margin-right: 6px;
}
// 合同项
.contractItem {
    font-size: 14px;
}
.contractItem .contractRow {
    background: #fff;
    margin-bottom: 15px;
}
.contractItem > .titlt {
    width: 214px;
}
.contractItem > .title span {
    vertical-align: middle;
}
.contractItem > .title .bluecircle {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: url("../../../assets/imgs/icon_blueCircle.png") no-repeat center
        center;
    background-size: 8px 8px;
    margin-right: 6px;
}
.contractItem > .title .redcircle {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: #f00;
    border-radius: 50%;
    margin-right: 6px;
}
.contractList {
    background-color: #fff;
    padding: 20px;
}
.contractList .contractItem {
    position: relative;
    line-height: 20px;
    font-size: 14px;
    padding: 8px 15px;
    padding-left: 92px;
    width: 380px;
    height: 101px;
    background: #fbfbfb url("../../../assets/imgs/icon_contract.png") no-repeat
        28px center;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #595959;
    line-height: 26px;
}
.contractList .contractItem .close {
    font-size: 18px;
    position: absolute;
    right: -8px;
    top: -8px;
    color: #1990ff;
    cursor: pointer;
    transition: all 0.5s;
}
.contractList .contractItem .close:hover {
    color: #ff0000;
    transition: all 0.5s;
}
.controlBox {
    padding-top: 70px;
    padding-left: 109px;
}
// 上传文件组件
.comfileBox ::v-deep .itemFile {
    position: relative;
}
.comfileBox ::v-deep .itemFile .downAndUpload {
    position: absolute;
    right: -107px;
    top: 70px;
}
</style>
