// 实施阶段 - 部署管理
<template>

    <div class="comImplementationPlanTable">
        <el-card>
            <div slot="header">部署管理</div>
            <div class="miniTable">
                <el-table :data="tableData.data"
                          border
                          stripe>
                    <el-table-column label="序号"
                                     type="index"
                                     align="center"
                                     width="100">
                    </el-table-column>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     align="center"
                                     :min-width="col.width">
                    </el-table-column>
                    <el-table-column prop="id"
                                     label="操作"
                                     align="center"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="openpopDevicePurchaseHistory(scope.row)">查看</el-link>
                            <el-link type="primary"
                                     v-if="scope.row.equipmentStatus.text && scope.row.equipmentStatus.text!=='完成'"
                                     @click="openaddDeployment(scope.row.taskId)">编辑</el-link>
                            <el-link type="danger"
                                     v-if="scope.row.equipmentStatus.text && scope.row.equipmentStatus.text!=='完成'"
                                     @click="openpopDelDeployment(scope.row)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>

                <template v-if="!disable">
                    <div class="addBar"
                         v-if="!isDisableUpload"
                         @click="openaddDeployment()">
                        + 新增部署
                    </div>
                </template>
                <div class="totalBar ofh">
                    <i class="blue el-icon-warning mr10"></i>
                    <span v-if="tableData.data">已添加<i>{{tableData.data.length}}</i>项</span>
                    <span>需求数量总计：<i class="blue">{{counts.totalNumber || 0}}</i>件</span>
                    <span>出库数量总计：<i class="blue">{{counts.totalOutNumber || 0}}</i>件</span>
                    <span>验收数量总计：<i class="blue">{{counts.totalCheckedNum || 0}}</i>件</span>
                </div>
            </div>
        </el-card>

        <!-- 新增部署 -->
        <pop-add-deployment v-if="addDeploymentVisible"
                            :detailsId="detailsId"
                            :isNeedTask="true"
                            :editDeploymentId="editDeploymentId"
                            @close="closeaddDeployment"
                            @update="update"></pop-add-deployment>
        <!-- 设备采购记录 -->
        <pop-device-purchase-history v-if="popDevicePurchaseHistoryVisible"
                                     :devicePurchaseItem="devicePurchaseItem"
                                     @close="closepopDevicePurchaseHistory"></pop-device-purchase-history>
        <!-- 删除部署管理 -->
        <pop-del-deployment v-if="popDelDeploymentVisible"
                            :delDeploymentId="delDeploymentId"
                            :delNeedDescription="delNeedDescription"
                            @delDeployment="handleDelDeployment"
                            @close="closepopDelDeployment"></pop-del-deployment>

    </div>

</template>

<script>
import PopDelDeployment from "./popDelDeployment";
import PopAddDeployment from "./popAddDeployment";
import popDevicePurchaseHistory from "./popDevicePurchaseHistory";
import API from "@/api/projectManage.js";
import { Loading } from "element-ui";
export default {
    name: "comPurchaseManageTable",

    props: {
        detailsId: {
            // 项目Id
            type: [String, Number],
            default: "",
        },
        isDisableUpload: {
            // 是否禁止上传或保存 true==禁止 false==允许
            type: Boolean,
            default: true,
        },
        // 全局禁用
        disable: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        PopDelDeployment,
        PopAddDeployment,
        "pop-device-purchase-history": popDevicePurchaseHistory,
    },

    data() {
        return {
            addDeploymentVisible: false, // 新增部署弹窗显示开关
            popDevicePurchaseHistoryVisible: false, // 设备采购记录弹窗显示
            popAllPurchaseVisible: false, // 设备采购记录总表弹窗
            devicePurchaseItem: null, // 设备采购记录选中项
            tableData: {
                data: [],
                columns: [
                    { id: "name", label: "实施计划", width: "100" },
                    {
                        id: "purchaseNumber",
                        label: "部署需求数量",
                        width: "100",
                    },
                    { id: "outNumber", label: "出库数量", width: "100" },
                    { id: "checkedNum", label: "验收数量", width: "100" },
                    { id: "deployNum", label: "安装数量", width: "100" },
                    { id: "equipmentStatus", label: "状态", width: "100" },
                ],
            },
            counts: {
                totalNumber: "", // 需求数量总计
                totalOutNumber: "", // 出库数量总计
                totalCheckedNum: "", // 验收数量总计
            },
            editDeploymentId: null, // 编辑部署的ID
            popDelDeploymentVisible: false, // 删除部署管理弹窗显示
            delDeploymentId: null, // 删除用部署id
            delNeedDescription: true, // 删除部署是否需要描述
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            // 获取列表
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".comImplementationPlanTable"),
            });
            API.getDeploymentManageList({
                projectId: this.$route.query.id,
            })
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.equipmentCountVos;
                    this.counts = {
                        totalNumber: res.content.totalNumber, // 需求数量总计
                        totalCheckedNum: res.content.totalCheckedNum, // 验收数量总计
                        totalOutNumber: res.content.totalOutNumber, // 出库数量总计
                    };
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 删除部署管理 打开弹框
        openpopDelDeployment(row) {
            this.delDeploymentId = row.taskId;
            this.delNeedDescription = row.outNumber > 0 ? true : false;
            this.popDelDeploymentVisible = true;
        },
        // 删除部署管理 关闭弹框
        closepopDelDeployment() {
            this.delDeploymentId = null;
            this.delNeedDescription = true;
            this.popDelDeploymentVisible = false;
        },
        // 触发删除部署管理
        handleDelDeployment(data) {
            let loading = Loading.service({
                target: document.querySelector(".miniTable"),
            });
            API.delDeployment(data)
                .then(() => {
                    loading.close();
                    this.update();
                    this.closepopDelDeployment();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 打开新增部署弹框
        openaddDeployment(id) {
            this.editDeploymentId = id || null;
            this.addDeploymentVisible = true;
        },
        // 关闭新增部署弹框
        closeaddDeployment() {
            this.editDeploymentId = null;
            this.addDeploymentVisible = false;
        },
        // 设备采购记录 打开弹框
        openpopDevicePurchaseHistory(row) {
            this.$router.push({
                path: "/deviceManage/taskDetails",
                query: { id: row.taskId },
            });
        },
        // 设备采购记录 关闭弹框
        closepopDevicePurchaseHistory() {
            this.devicePurchaseItem = null;
            this.popDevicePurchaseHistoryVisible = false;
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.addBar {
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    background: #d8f4ff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #00a2e9;
    text-align: center;
    cursor: pointer;
}
// 合计栏
.totalBar {
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #9093b3;
    padding-left: 15px;
}
.totalBar span {
    margin-right: 15px;
}
</style>
