// 编辑实施计划
// popEditImplementationPlan
<template>

    <div class="popEditImplementationPlan">
        <el-dialog title="编辑实施计划 "
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     :inline="false">
                <el-form-item label="实施负责人"
                              prop="operatorName">
                    <el-row>
                        <el-col :span="12">
                            <el-input v-model="form.operatorName"
                                      disabled
                                      suffix-icon="el-icon-arrow-down"
                                      placeholder="请选择实施负责人"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="success"
                                       class="ml15"
                                       size="mini"
                                       @click="openselectPerson">添加</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>
        </el-dialog>

        <!-- 选择客户 -->
        <pop-select-members v-if="selectPersonVisible"
                            title="添加成员"
                            @close="closeselectPerson"></pop-select-members>
    </div>
</template>

<script>
import PopSelectMembers from "../../../components/selectTree/popSelectMembers";
import { Loading } from "element-ui";
import API from "@/api/safeguardManage.js";
export default {
    name: "popEditImplementationPlan",

    props: {
        // 编辑实施计划
        editImplementationPlan: {
            type: Object,
            default: null,
        },
    },

    components: {
        PopSelectMembers,
    },

    data() {
        return {
            dialogVisible: true,
            selectPersonVisible: false, // 选择实施负责人

            form: {
                taskId: "",
                operatorId: "", // 实施负责人Id
                operatorName: "", // 实施负责人姓名
            },
            formRules: {
                // 实施负责人
                operatorName: [{ required: true, message: "请选择实施负责人" }],
            },
        };
    },

    created() {},

    mounted() {
        if (this.editImplementationPlan) {
            this.form.operatorId = this.editImplementationPlan.operatorId;
            this.form.operatorName = this.editImplementationPlan.operatorName;
            this.form.taskId = this.editImplementationPlan.id
        }
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        // 选择添加客户
        openselectPerson() {
            this.selectPersonVisible = true;
        },
        // 关闭选择添加客户弹框
        closeselectPerson(id, name) {
            if (id) {
                this.$set(this.form, "operatorId", id); // 项目经理
                this.$set(this.form, "operatorName", name); // 项目经理名字
            }
            this.selectPersonVisible = false;
        },
        // 保存实施负责人
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(".footer"),
                    });
                    API.changeOperator(this.form)
                        .then(() => {
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            this.nowRow = "";
                            this.$emit("update");
                            loading.close();
                            this.close();
                        })
                        .catch(() => {
                            loading.close();
                        });
                } else {
                    this.$message({
                        message: "请检查必填信息是否填写完整！",
                        type: "error",
                    });
                    return false;
                }
            });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>