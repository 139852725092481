// 项目信息
<template>

    <div class="itemInfo">

        <el-card>
            <div slot="header">
                <span>项目信息</span>
            </div>
            <el-form :model="projectDetails"
                     v-if="projectDetails"
                     ref="projectDetails"
                     :rules="projectDetailsRules"
                     label-width="150px"
                     label-position="top"
                     :inline="false">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="关联项目机会"
                                      prop="reserveProjectName">
                            <!-- 关闭跳转 -->
                            <!-- @click="toProjectDetails(projectDetails.reserveProjectId)" -->
                            <div class="itemCard"
                                 v-if="projectDetails.reserveProjectName">
                                <el-popconfirm confirm-button-text="删除"
                                               cancel-button-text="取消"
                                               icon="el-icon-info"
                                               icon-color="red"
                                               @confirm="cleanItem"
                                               title="真的要删除吗？">
                                    <el-link type="danger">删除</el-link>
                                    <span class="close el-icon-error"
                                          @click.stop
                                          slot="reference"></span>
                                </el-popconfirm>
                                {{projectDetails.reserveProjectName}}
                            </div>
                            <el-link type="primary"
                                     v-else
                                     @click="openpopSelectReserveProject">点击选择项目机会</el-link>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="companyId">
                            <span slot="label"><i class="red mr5">*</i>客户</span>
                            <customer-or-provider-select-tree ref="selectTree"
                                                              type="CUSTOMER"
                                                              :checkedInfo="{value: projectDetails.companyId}"
                                                              class="fll"
                                                              @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                            <!-- 关闭跳转 -->
                            <!-- <el-link type="primary"
                                     v-if="projectDetails.companyId"
                                     class="fll"
                                     :underline="false"
                                     @click="toPersonDetails"
                                     target="_blank">查看</el-link> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="projectSystem">
                            <span slot="label"><i class="red mr5">*</i>项目产品</span>
                            <select-project ref="selectProject"
                                            class="fll"
                                            :chooseData="projectDetails.systemId"
                                            @select="selectSystem"></select-project>
                            <!-- 关闭跳转 -->
                            <!-- <el-link type="primary"
                                     v-if="projectDetails.systemId"
                                     class="fll"
                                     :underline="false"
                                     @click="toItemDetails"
                                     target="_blank">查看</el-link> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item prop="managerId">
                            <span slot="label"><i class="red mr5">*</i>项目经理</span>
                            <div class="itemCard person"
                                 v-if="projectDetails.managerName">
                                <el-popconfirm confirm-button-text="删除"
                                               cancel-button-text="取消"
                                               icon="el-icon-info"
                                               icon-color="red"
                                               @confirm="cleanManager"
                                               title="真的要删除吗？">
                                    <el-link type="danger">删除</el-link>
                                    <span class="close el-icon-error"
                                          slot="reference"></span>
                                </el-popconfirm>
                                {{projectDetails.managerName}}
                            </div>
                            <el-link type="primary"
                                     v-else
                                     @click="openselectPerson">点击选择项目经理</el-link>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="项目开始时间"
                                      prop="startTime">
                            <el-date-picker v-model="projectDetails.startTime"
                                            type="datetime"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            placeholder="请选择项目开始时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="planFinishTime">
                            <span slot="label"><i class="red mr5">*</i>计划交付日期</span>
                            <el-date-picker v-model="projectDetails.planFinishTime"
                                            type="datetime"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            placeholder="请选择计划交付日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item prop="belongDeptId">
                            <span slot="label">
                                <i class="red mr5">*</i>
                                项目归属部门
                                <el-tooltip class="item"
                                            effect="dark"
                                            content="用于部门的成本和利润统计，不影响项目管理权限"
                                            placement="top-start">
                                    <i class="orange el-icon-warning"></i>
                                </el-tooltip>
                            </span>
                            <dept-select-tree placeholderStr="请选择项目归属部门"
                                              :disabled="!isEditMoney"
                                              :needClean="false"
                                              :shouldClean="false"
                                              :checkedInfo="{value: projectDetails.belongDeptId}"
                                              @handClear="clearDept"
                                              @treeSelectNodeClick="departmentClick"></dept-select-tree>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="项目规模（元）"
                                      prop="money">
                            <el-input-number v-model="projectDetails.money"
                                             :step="0.1"
                                             min="0"
                                             :disabled="!isEditMoney"
                                             :controls="false"
                                             placeholder="请输入项目规模"></el-input-number>

                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="supplemenFlag">
                            <span slot="label"><i class="red mr5">*</i>是否增补项目</span>
                            <el-radio v-model="projectDetails.supplemenFlag"
                                      label="是">是</el-radio>
                            <el-radio v-model="projectDetails.supplemenFlag"
                                      label="否">否</el-radio>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="项目内容"
                                      prop="content">
                            <el-input type="textarea"
                                      :rows="5"
                                      placeholder="请输入项目内容"
                                      v-model="projectDetails.content">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <!-- 选择储备项目 -->
        <pop-select-reserve-project v-if="popSelectReserveProjectVisible"
                                    @close="closepopSelectReserveProject"></pop-select-reserve-project>
        <!-- 选择客户 -->
        <pop-select-person v-if="selectPersonVisible"
                           title="选择客户"
                           @close="closeselectPerson"></pop-select-person>

        <!-- 添加成员 -->
        <pop-select-members v-if="selectPersonVisible"
                            title="添加成员"
                            @close="closeselectPerson"></pop-select-members>
    </div>

</template>

<script>
import DeptSelectTree from "../../../components/selectTree/deptSelectTree";
import customerOrProviderSelectTree from "@/components/selectTree/customerOrProviderSelectTree.vue";
import popSelectMembers from "@/components/selectTree/popSelectMembers.vue";
import selectProject from "@/components/commonSelect/selectProject.vue";
import popSelectPerson from "../../safeguardManage/components/popSelectPerson.vue";
import commonAPI from "@/api/commonAPI.js";
import { Loading } from "element-ui";
import popSelectReserveProject from "./popSelectReserveProject";

export default {
    name: "itemInfo",

    props: {
        step: {
            type: String,
            default: "初步机会",
        },
        isEditMoney: {
            // 是否可以编辑项目规模
            type: Boolean,
            default: true,
        },
        projectDetails: {
            type: Object,
            default() {
                return {
                    reserveProjectName: "", // 关联储备项目
                    companyId: null, // 客户
                    projectSystem: "", // 项目产品
                    managerId: "", // 项目经理
                    managerName: "", // 项目经理名字
                    startTime: "", // 项目开始时间
                    planFinishTime: "", // 计划交付日期
                    money: "", // 项目规模（元）
                    supplemenFlag: "否", // 是否增补项目
                    content: "", // 项目内容
                };
            },
        },

        // 全局禁用
        disable: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        DeptSelectTree,
        "pop-select-reserve-project": popSelectReserveProject,
        "pop-select-person": popSelectPerson,
        "select-project": selectProject,
        "customer-or-provider-select-tree": customerOrProviderSelectTree,
        "pop-select-members": popSelectMembers,
    },

    data() {
        return {
            popAddSystemVisible: false, // 新增系统弹窗显示
            popSelectReserveProjectVisible: false, // 选择储备项目弹窗显示
            selectPersonVisible: false, // 选择客户弹窗显示
            linkReserveItem: "", // 选择的关联的储备项目
            personInfo: "", // 选择的客户
            projectDetailsRules: {},
            customerOpts: [], // 目标客户 下拉
            tableData: {
                data: [],
                columns: [
                    { id: "系统名称", label: "系统名称", width: "100" },
                    { id: "所属部门", label: "所属部门", width: "50" },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 查看客户详情
        toPersonDetails() {
            let data = {
                value: this.projectDetails.companyId,
                type: "CUSTOMER", // PROVIDER：供应商信息  CUSTOMER：客户信息
            };
            let routeData = this.$router.resolve({
                path: "/baseInfoManage/supplierDetail",
                query: { deviceTypeInfo: JSON.stringify(data) },
            });
            window.open(routeData.href, "_blank");
        },
        // 查看项目产品详情
        toItemDetails() {
            let data = {
                id: this.projectDetails.systemId,
                name: this.projectDetails.projectSystem,
            };
            let routeData = this.$router.resolve({
                path: "/baseInfoManage/systemDetail",
                query: { systemInfo: JSON.stringify(data) },
            });
            window.open(routeData.href, "_blank");
        },
        // 查看项目详情
        toProjectDetails(id) {
            let routeData = this.$router.resolve({
                path: "/reserveProject/reserveProjectDetails",
                query: {
                    status: "编辑",
                    step: "初步机会",
                    id,
                    disable: "true",
                },
            });
            window.open(routeData.href, "_blank");
        },
        //部门的选择
        departmentClick(node) {
            this.projectDetails.belongDeptId = node.id;
        },
        //部门清空
        clearDept() {
            this.projectDetails.belongDeptId = "";
        },
        // 客户的选择
        selectTreeCustClick(node) {
            this.projectDetails.companyId = node.id;
        },
        // 项目经理的选择
        selectTreemanagerId(node) {
            this.projectDetails.managerId = node.id;
        },
        // 项目产品的选择
        selectSystem(data) {
            this.projectDetails.systemId = data.value;
            this.projectDetails.projectSystem = data.label;
        },
        // 清空关联储备项目
        cleanItem() {
            this.projectDetails.reserveProjectName = "";
            this.projectDetails.reserveProjectId = "";
            this.linkReserveItem = "";
        },
        // 选择储备项目 打开弹框
        openpopSelectReserveProject() {
            this.popSelectReserveProjectVisible = true;
        },
        // 选择储备项目 关闭弹框
        closepopSelectReserveProject(row) {
            if (row) {
                this.projectDetails.reserveProjectName = row.name;
                this.projectDetails.reserveProjectId = row.id;
                this.linkReserveItem = row;
            }
            this.popSelectReserveProjectVisible = false;
        },
        // 新增系统 保存
        addSystem(selectNode) {
            this.tableData.data.push(selectNode);
        },
        // 获取目标客户 树
        getCustomerTree() {
            commonAPI
                .getCustomerSelectTree({ type: "CUSTOMER" })
                .then((res) => {
                    this.customerOpts = res.content;
                })
                .catch(() => {
                    this.$message({
                        message: "获取目标客户失败，请稍后再试！",
                        type: "error",
                    });
                });
        },
        // 查看
        details() {},
        // 删除
        del() {},

        // 选择添加项目经理
        openselectPerson() {
            this.selectPersonVisible = true;
        },
        // 关闭选择添加项目经理弹框
        closeselectPerson(id, name) {
            if (name) {
                this.$set(this.projectDetails, "managerId", id); // 项目经理
                this.$set(this.projectDetails, "managerName", name); // 项目经理名字
            }
            this.selectPersonVisible = false;
        },
        // 删除项目经理
        cleanManager() {
            this.$set(this.projectDetails, "managerId", ""); // 项目经理
            this.$set(this.projectDetails, "managerName", ""); // 项目经理名字
        },
    },

    computed: {},

    watch: {
        // 目标客户选择后 自动带入任务联系人
        "projectDetails.customerCompanyId": function () {
            // 清空任务联系人
            this.form.taskLinkName = "";
            this.form.taskLinkPhone = "";
            // 重新获取任务联系人
            let loading = Loading.service({
                target: document.querySelector(".taskLink"),
            });
            commonAPI
                .getLinkPersonList({
                    companyId: this.form.customerCompanyId,
                })
                .then((res) => {
                    if (res.content && res.content.length > 0) {
                        let defaultPerson = res.content.filter(
                            (item) => item.isDefault
                        );
                        this.form.taskLinkName = defaultPerson[0].code;
                        this.form.taskLinkPhone = defaultPerson[0].label;
                    }
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.itemInfo .el-card__header .pic {
    float: right;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
}
.itemInfo .el-card__header .pic span {
    font-size: 26px;
}
// 选中的数据条带删除按钮
.itemCard {
    position: relative;
    padding: 0 20px 0 40px;
    border: 1px solid #d9d9d9;
    background: #f3f6f9 url("../../../assets/imgs/icon_itemIco.png") no-repeat
        16px 7px;
    font-size: 16px;
    color: #7a7a7b;
}
.itemCard.person {
    background-size: 20px;
    background-image: url("../../../assets/imgs/icon_person.png");
}
.itemCard .close {
    position: absolute;
    right: -7px;
    top: -7px;
    color: #8290a3;
    transition: all 0.5s;
    cursor: pointer;
}
.itemCard .close:hover {
    color: #ff0000;
    transition: all 0.5s;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner,
.el-input-number {
    width: auto;
}
::v-deep .el-form-item__label {
    white-space: inherit;
}
</style>
